const initialState = { page: 'home' }

function storePreviousPage(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_PREVIOUS_PAGE':
      nextState = { page:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storePreviousPage;
