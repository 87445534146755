const initialState = { name: ''}

function storeActiveRCName(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_RC_NAME':
      nextState = { name:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeActiveRCName;
