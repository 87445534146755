/**
 * Call API to edit reality capture.
 * PUT /reality-captures/:realityCaptureId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - reality capture id
 * @param {string} name - reality capture name
 * @param {date} date - reality capture date
 */
const putRealityCapture = async (token, id, name, date) => {
  const url = `${process.env.REACT_APP_HOST}/reality-captures/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify({
      name: name,
      date: date
    })
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    const responsemsg = {
      message: 'Reality capture edited with success',
      detail: jsonResponse
    };
    return responsemsg;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to edit reality capture',
      detail: err
    };
    if (err.message === 'Asset with such name already exists!') {
      errormsg.message = 'Asset with such name already exists!';
    }
    console.log(errormsg);
    return errormsg;
  }
};

export default putRealityCapture;
