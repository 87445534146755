/**
 * Calls Thingsboard API to log user out.
 *
 * @param {string} token - user JWT token
 */
const logout = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_TB}/api/auth/logout`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  return response;
};

export default logout;
