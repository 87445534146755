const initialState = { allow: false }

function storePermission(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_PERMISSIONS':
      nextState = { allow:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storePermission;
