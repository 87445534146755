// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// # MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import store from '../../../redux/store';
import './SelectTime.css';

// # MAIN
/**
 * This class allows the rendering of a time selection dialog for the area
 *    charts.
 *
 * @param {string} timePeriod - a string representing the amount of time
 *    querried for telemetry data
 * @param {interger} limit - the maximum amount of telemetry data to be fetched
 *    from ThingsBoard API
 * @param {function} timePeriodHasChangedCallback - callback for when the user
 *    has changed the time limit for the telemetry data fetch
 */
class SelectTime extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      name : '',
      message: '',
      loading: false,
      timePeriod: 'last24',
      limit: 100
    }
  }

  /**
   * Saves props values as state variables on component update.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.timePeriod !== prevProps.timePeriod) {
      this.setState({
        timePeriod: this.props.timePeriod,
        limit: this.props.limit
      })
    }
  }

  /**
   * Toggles the specified modal.
   *
   * @params {integer} nr - the modal number
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <Button onClick={this.toggle(14)}>
          Time Period
        </Button>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}>
          <DialogTitle>Select Time Period</DialogTitle>
          <DialogContent>
            <form onSubmit={preventDefault}>
              <FormControl variant='standard' fullWidth>
                <InputLabel id="timePeriodSelectLabel">Time Period</InputLabel>
                <Select
                  id='timePeriodSelect'
                  labelId='timePeriodSelectLabel'
                  value={this.state.timePeriod}
                  onChange={this.handleChange}
                >
                  <MenuItem value={'last1'}>Last Hour</MenuItem>
                  <MenuItem value={'last24'}>Last 24h</MenuItem>
                  <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                  <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                  <MenuItem value={'lastYear'}>Last Year</MenuItem>
                </Select>
              </FormControl>
              <div style={{color: 'red'}}>
                If there is more than {this.props.limit} values, adapt the
                'Value Limit' accordingly
              </div>
              <FormControl fullWidth>
                <TextField
                  id='valueLimitTextInput'
                  label='Value Limit'
                  defaultValue={this.state.limit}
                  variant='standard'
                  type='number'
                  onChange={this.handleLimitChange}
                />
              </FormControl>
              <div className='msg'>
                {loadingRender(this.state.loading, this.state.message)}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary'
              onClick={this.toggle(14)}>
                Close
            </Button>
            <Button variant='primary'
              onClick={this.submitHandler}>
                Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // ## FORM SERVICE
  /**
   * Calls the timePeriodHasChangedCallback callback.
   */
  submitHandler = () => {
    this.props.timePeriodHasChangedCallback(this.state.timePeriod,
      this.state.limit);
    this.setState({ modal14: false });
  }

  /**
   * Saves the value of the 'sortOption' field.
   *
   * @returns event - the sortOption field changed
   */
  handleChange = (event) => {
    this.setState({ timePeriod: event.target.value });
  }



  /**
   * Saves the value of the 'limit' field.
   *
   * @returns event - the limit field changed
   */
  handleLimitChange = (event) => {
    this.setState({ limit: event.target.value });
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
  }
}

export default connect(mapStateToProps)(SelectTime);
