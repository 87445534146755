/**
 * Calls API to edit point of interest coordinates.
 * PUT /point-of-interests/:pointOfInterestId/coordinates/reality-capture/:realityCaptureId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 */
export async function putPointOfInterestCoordinates (token, id, coordinates,
  rcId) {
    const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}/` +
      `coordinates/reality-capture/${rcId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      },
      body: JSON.stringify({
        coordinates: coordinates
      })
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      return jsonResponse;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to get points of interest',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};

/**
 * Calls API to edit point of interest severity
 * PUT /point-of-interests/:pointOfInterestId/severity/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 * @param {integer} severity - point of interest severity
 */
export async function putPointOfInterestSeverity (token, id, severity) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}/severity`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify({
      severity: severity
    })
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to edit severity',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};
