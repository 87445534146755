// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WarningIcon from '@mui/icons-material/Warning';

// ## LOCAL IMPORTS
import loadingRender from '../../services/misc/loading';
import { deleteAlarm } from '../../services/thingsboard/alarms';
import store from '../../redux/store';
import './DeleteAlarm.css';

// # MAIN
/**
 * This class allows deletion of alarms.
 * @param {boolean} isOpen - the deletion dialog is open
 * @param {function} alarmWasDeletedCallback - callback for when an alarm is
 *    deleted
 * @param {string} alarmId - alarm id
 */
class DeleteAlarm extends React.Component {
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: [],
      modal: false,
      id: '',
      delmsg:'',
    };
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <>
        <Dialog open={this.props.isOpen}>
          <DialogContent>
            <p className='warningContainer'>
              <WarningIcon className='warning'/>
            </p>
            <span>Are you sure you want to delete this alarm ?</span>
            {loadingRender(this.state.loading, this.state.delmsg)}
          </DialogContent>
          <DialogActions>
            <Button variant='secondary'
              onClick={() => {
                this.setState({delmsg: ''})
                this.props.alarmWasDeletedCallback();
              }}>
                NO
            </Button>
            <Button variant='secondary'
              onClick={() => {
                this.deleteAlarm(this.state.id);
                this.setState({delmsg: ''});
              }}>
                YES
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // ALARM SERVICE
  /**
   * Deletes a jobsite.
   */
  deleteAlarm = async () => {
    this.setState({ loading: true });
    const response = await deleteAlarm(this.props.token,
      this.props.alarmId);
    this.setState({
      loading: false,
    });
    this.props.alarmWasDeletedCallback();
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *     permission, commentId -redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    permission: state.storePermission.allow,
    commentId: state.storeCommentId.commentId
  }
}

export default connect(mapStateToProps)(DeleteAlarm);
