/**
 * Create a comment.
 * POST /comments/
 *
 * @param {string} contenu - comment content
 * @param {string} idPoints - associated point of interest id
 * @returns {object} - response message
 */
export const postComment = async (token, content, tbEntityId, tbEntityType) => {
  const url = `${process.env.REACT_APP_HOST}/comments`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify({
      content: content,
      tbEntityId: tbEntityId,
      tbEntityType: tbEntityType
    }),
  });
  try {
    const jsonResponse = await response.json();
    let responseMessage = { message: 'Comment added with success.' }
    if (jsonResponse.status) { throw jsonResponse; }
    return responseMessage;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to post comment',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

export default postComment;
