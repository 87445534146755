// # IMPORTS
import React from 'react';

// ## LOCAL IMPORTS
import Login from '../../components/login/Login';
import './Welcome.css';

// # MAIN
/**
 * This class allows the rendering of the 'Welcome' page.
 */
export default class Welcome extends React.Component{
  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return(
      <Login/>
    )
  }
}
