/**
 * Calls API to get user's jobsites.
 * GET /jobsites/
 *
 * @param {string} token - user's JWT token
 * @returns {[object]} - API response or placeholder message
 */
export const getJobsites = async (token) => {
  const url = `${process.env.REACT_APP_HOST}/jobsites`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get user jobsites',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

export default getJobsites;
