/**
 * Gets user reality captures from selected jobsite.
 * GET /reality-captures/jobsite/:jobsiteId/
 *
 * @param {string} token - user JWT token
 * @param {string} jobsiteId - jobsite id
 */
export async function getRealityCapturesFromJobsiteId(token, jobsiteId) {
  const url = `${process.env.REACT_APP_HOST}/reality-captures/jobsite/` +
    `${jobsiteId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get the reality captures',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};
