// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import Input from '@mui/material/Input';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import { postPointOfInterest, postPointOfInterestJobsiteRelation }
  from '../../../services/api/pointsOfInterest/postPointOfInterest';
import { getUserPointsOfInterest }
  from '../../../services/api/pointsOfInterest/getPointsOfInterest';
import store from '../../../redux/store';
import './AddDevice.css';

// # MAIN
/**
 * This class allows the rendering of an 'Add Device' dialog.
 *
 * @param {function} deviceWasCreatedCallback - callback for when a device is
 *    created
 * @param {[object]} jobsiteDevices - list of devices associated with the active
 *    jobsite
 */
class AddDevice extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      modal15: false,
      data: [],
      selectedDate: new Date(),
      selectedDevice: '',
      fileObj: null,
      fileExt: '',
      name : '',
      message: '',
      loading: false,
      today : new Date().toISOString().substring(0,10),
      type: ''
    }
  }

  /**
   * Gets user's devices on component mount.
   */
  componentDidMount = () => {
    this.getUserPointsOfInterest();
  }

  /**
   * Toggles the specified modal.
   *
   * @params {integer} nr - the modal number
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  /**
   * Generates the list of devices already associated to the active jobsite.
   */
  generateUnavailableDeviceList = () => {
    let unavailableDevices = [];
    for (let i in this.props.jobsiteDevices) {
      unavailableDevices.push(this.props.jobsiteDevices[i].name);
    }
    return unavailableDevices;
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <CachedIcon className='iSyncm'
          style={{ margin: '5px' }}
          onClick={()=>{this.props.deviceWasCreatedCallback();}}/>
        <AddIcon className='iPlus'
          style={{ margin: '5px' }}
          onClick={this.toggle(14)}/>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}>
          <DialogTitle>Add device</DialogTitle>
          <DialogContent>
            <Button variant='primary' children='Select from owned devices'
              onClick={() => {
                this.setState({
                  modal14: false,
                  modal15: true,
                });
              }}>
            </Button>
            <form onSubmit={preventDefault}>
              <FormControl variant='standard' fullWidth>
                <TextField
                  required
                  id='addDeviceNameTextInput'
                  label='Device name'
                  onChange={this.handleName}
                  variant='standard'
                />
              </FormControl>
                <FormControl variant='standard' fullWidth>
                  <TextField
                    required
                    id='addDeviceTypeTextInput'
                    label='Device type'
                    onChange={this.handleType}
                    variant='standard'
                  />
                </FormControl>
              <div className='msg'>
                {loadingRender(this.state.loading, this.state.message)}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary' children='Close'
              onClick={this.toggle(14)}>
            </Button>
            <Button variant='primary' children='Save'
              onClick={this.submitHandler}>
            </Button>
          </DialogActions>
        </Dialog>
      <Dialog open={this.state.modal15} onClose={this.toggle(15)}>
        <DialogTitle>Select device</DialogTitle>
        <DialogContent>
          <Button variant='primary' children='Create new device'
            onClick={() => {
              this.setState({
                modal15: false,
                modal14: true,
              });
            }}>
          </Button>
          <form onSubmit={preventDefault}>
            <FormControl variant='standard' fullWidth>
              <InputLabel id="sortJobsitesSelectLabel">devices</InputLabel>
              <Select
                id='sortJobsitesSelect'
                labelId='sortJobsitesSelectLabel'
                value={this.state.selectedDevice}
                onChange={this.handleCoordinates}
              >
                {this.renderDeviceList()}
              </Select>
            </FormControl>
            <div className='msg'>
              {loadingRender(this.state.loading, this.state.message)}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant='secondary' children='Close'
            onClick={this.toggle(15)}>
          </Button>
          <Button variant='primary' children='Save'
            onClick={this.submitHandlerForModal15}>
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
  }

  /**
   * Renders the list existing devices not currently associated to the active
   *    jobsite.
   */
  renderDeviceList = () => {
    const data = this.state.data;
    const unavailableDevices = this.generateUnavailableDeviceList();
    let devices = [];
    let items = [];
    for (const [value] of data.entries()) {
      if (!unavailableDevices.includes(data[value].name)) {
        items.push(
          <MenuItem value={data[value].id.id} key={value}
            onClick={()=> {
              this.setState({
                selectedDevice: data[value].id.id
              })
            }}>
              {data[value].name}
          </MenuItem>
        );
      }
    }
    items.map( (item) => {
      return items
    })
    return items;
  }

  // DEVICES SERVICE
  /**
   * Calls API to get all user's devices.
   */
  getUserPointsOfInterest = async () => {
    const response = await getUserPointsOfInterest(this.props.token);
    this.setState({
      data: response
    });
  }

  /**
   * Creates a relation between active jobsite and selected device.
   *
   * @param {string} poiId - device id
   * @param {string} jobsiteId - active jobsite id
   */
  postRelation = async (poiId, jobsiteId) => {
    this.setState({ loading: true });
    const response = await postPointOfInterestJobsiteRelation(this.props.token,
      poiId, jobsiteId);
    if (response.message) {
      this.setState({
        message: response.message,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        modal15: false
      });
      this.props.deviceWasCreatedCallback();
    }
  };

  /**
   * Creates a new device.
   *
   * @param {string} name - new device name
   * @param {string} type - new device type
   */
  postDevice = async (name, type) => {
    this.setState({ loading: true });
    const response = await postPointOfInterest(this.props.token, name,
      this.props.jobsiteId, type);
    if (response.message) {
      this.setState({
        message: response.message,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
        modal14: false
      });
      this.props.deviceWasCreatedCallback();
    }
  };

  // ## FORM SERVICE
  /**
   * Calls the postPlan function.
   */
  submitHandler = () => {
    this.postDevice(this.state.name, this.state.type);
  }
  /**
   * Calls the postPlan function.
   */
  submitHandlerForModal15 = () => {
    this.postRelation(this.state.selectedDevice, this.props.jobsiteId);
  }
  /**
   * Saves device name.
   *
   * @param event - change of name field value
   */
  handleName = (event) => {
    this.setState({name: event.target.value});
  }

  /**
   * Saves device type.
   *
   * @param event - change of type field value
   */
  handleType = (event) => {
    this.setState({type: event.target.value});
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, nom, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
  }
}

export default connect(mapStateToProps)(AddDevice);
