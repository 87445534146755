// # IMPORTS
import React from 'react';

// # MAIN
/**
 * This class allows the rendering of the 'About' page.
 */
class About extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return(
      <div style={{margin: '20px', overflowY: 'scroll', height: '85vh'}}>
        <h1>Initiative</h1>
        Digital Construction est une initiative du Centre scientifique et
        technique de la construction (CSTC). L’initiative ‘Digital Construction’
        vise à promouvoir la numérisation dans le secteur de la construction et
        à soutenir et guider les professionnels dans cette voie. Ce faisant, il
        importe à la fois d’examiner ce qui est disponible aujourd’hui et de se
        tourner vers l’avenir.
        <p></p>
        L’évolution numérique crée un contexte en mouvement perpétuel qui nous
        offre sans cesse de nouvelles possibilités. Mais toutes les technologies
        ne sont pas aussi intéressantes pour tous les professionnels. En
        fonction du corps de métier et des besoins et exigences spécifiques,
        certaines technologies apporteront une valeur ajoutée tandis que
        d’autres ne le feront pas. La numérisation n’est donc pas une fin en soi,
        mais fournit des outils permettant d’atteindre une plus grande efficacité
        et une meilleure qualité. Chaque professionnel doit donc faire des choix
        réfléchis dans le large éventail de technologies proposées.
        Digitalconstruction.be leur vient en aide en listant et en décrivant
        toutes sortes d’applications différentes des technologies, adaptées aux
        différents corps de métier et axées sur le chantier.
        Ne vous laissez donc pas décourager par les nombreuses nouvelles
        technologies qui se présentent à vous, mais laissez-vous guider par
        digitalconstruction.be. Utilisez les différentes options de recherche
        pour découvrir les technologies susceptibles de vous intéresser. Si vous
        le souhaitez, réservez une démonstration en direct dans l’un de nos hubs
        de démonstration et utilisez toutes ces informations pour faire des
        choix réfléchis et adaptés à votre entreprise, afin d’accroître votre
        efficacité.
        <h1>Initiative</h1>
        Digital Construction est un terme générique utilisé pour regrouper
        toutes sortes d’actions liées à la numérisation dans le secteur de la
        construction et les rendre reconnaissables pour les professionnels. Il
        s’agit d’une initiative du Centre scientifique et technique de la
        construction (CSTC).
        <h1>Actions</h1>
        Les actions dans le cadre de la numérisation se concentrent sur les
        applications dans le secteur de la construction et visent une plus
        grande efficacité et une meilleure qualité. La numérisation n’est pas
        une fin en soi, mais offre des outils supplémentaires pour y parvenir.
      </div>
    )
  }
}

export default (About);
