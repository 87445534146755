const initialState = { poiId: ''}

function storeActivePoIId(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_POI_ID':
      nextState = { poiId:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeActivePoIId;
