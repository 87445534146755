/**
 * Calls Thingsboard's API to authenticate the user and get its JWT token.
 *
 * @param {string} email - user's login email
 * @param {string} password - cuser's login password
 */
const login = async (email, password) => {
  const response = await fetch(`${process.env.REACT_APP_TB}/api/auth/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: email,
      password: password,
    })
  });
  try {
    const jsonResponse = await response.json();
    if (!jsonResponse.token) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to log user in',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

export default login;
