// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import putRealityCapture
  from '../../../services/api/realityCaptures/putRealityCapture';
import store from '../../../redux/store';
import './EditRealityCapture.css';

// # MAIN
/**
 * This class allows the rendering of a 'edit reality capture' dialog.
 */
class EditRealityCapture extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      selectedDate: new Date(),
      name : '',
      message: '',
      loading: false,
    }
  }

  /**
   * Toggles the 'Edit reality capture' popup.
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return (
      <>
        <EditIcon className='iEdit'
          style={{ margin: '5px' }}
          onClick={this.toggle(14)}/>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}>
          <DialogTitle>Edit reality capture</DialogTitle>
          <DialogContent>
            <form onSubmit={preventDefault}>
              <Stack spacing={3}>
                <FormControl variant='standard' fullWidth>
                  <TextField className='formitems'
                    required
                    id='addRCNameTextInput'
                    label='New name'
                    onChange={this.handleName}
                    variant='standard'
                  />
                </FormControl>
                <FormControl variant='standard' fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker required
                      maxDate={this.state.today}
                      label="Reality Capture date"
                      value={this.state.selectedDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={this.handleDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
                <div className='msg'>
                  {loadingRender(this.state.loading, this.state.message)}
                </div>
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary' children='Close'
              onClick={this.toggle(14)}>
            </Button>
            <Button variant='primary' children='Save'
              onClick={this.submitHandler}>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // ## REALITY CAPTURES SERVICE
  /**
   * Call API to edit reality capture.
   *
   * @param {date} date - reality capture date
   */
  putRC = async (date) => {
    this.setState({loading: true});
    const response = await putRealityCapture(this.props.token,
        this.props.rcId, this.state.name, date);
    this.setState({
      message: response.message,
      loading: false
    });
    if (response.message === 'Reality capture edited with success') {
      const action7 = { type: 'SET_RC_NAME', value: this.state.name};
      this.props.dispatch(action7);
    }
  };

  // ## FORM SERVICE
  /**
   * Calls the putPlan function.
   *
   * @param event - form submission
   */
  submitHandler = (event) => {
    let d = this.state.selectedDate;
    let dd = new Date(d);
    event.preventDefault();
    this.putRC(dd.toISOString());
  }

  /**
   * Saves reality capture date.
   *
   * @param newValue - new date value
   */
  handleDate = (newValue) => {
    this.setState({selectedDate: newValue});
  }

  /**
   * Saves reality capture name.
   *
   * @param event - change of name field value
   */
  handleName = (event) => {
    this.setState({name: event.target.value});
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
    return {
        id: state.storeUserId.id,
        isLoggedIn: state.isLogged.isLoggedIn,
        token: state.storeToken.token,
        name: state.storeActiveRCName.name,
        jobsiteId: state.storeActiveJobsiteId.jobsiteId,
        rcId: state.storeActiveRCId.rcId,
    }
}

export default connect(mapStateToProps)(EditRealityCapture);
