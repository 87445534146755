// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// # MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

// ## LOCAL IMPORTS
import loadingRender from '../../services/misc/loading';
import store from '../../redux/store';
import './SortJobsites.css';

// # MAIN
/**
 * This class allows the rendering of a 'sort jobsites' dialog.
 *
 * @param {function} sortOptionHasChangedCallback - callback for when the user
 *    selects a new sorting option
 */
class SortJobsites extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      name : '',
      message: '',
      loading: false,
      sortOption: 'date'
    }
  }

  /**
   * Toggles the specified modal.
   *
   * @params {integer} nr - the modal number
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <Button  variant='secondary' onClick={this.toggle(14)}>
          Sort
        </Button>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}>
          <DialogTitle>Sort jobsites</DialogTitle>
          <DialogContent>
            <form onSubmit={preventDefault}>
              <FormControl variant='standard' fullWidth>
                <InputLabel id="sortJobsitesSelectLabel">Sort option</InputLabel>
                <Select
                  id='sortJobsitesSelect'
                  labelId='sortJobsitesSelectLabel'
                  value={this.state.sortOption}
                  onChange={this.handleChange}
                >
                  <MenuItem value={'date'}>By date</MenuItem>
                  <MenuItem value={'name'}>By name</MenuItem>
                </Select>
              </FormControl>
              <div className='msg'>
                {loadingRender(this.state.loading, this.state.message)}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary'
              onClick={this.toggle(14)}>
                Close
            </Button>
            <Button variant='primary'
              onClick={this.submitHandler}>
                Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // ## FORM SERVICE
  /**
   * Calls the sortOptionHasChangedCallback callback.
   */
  submitHandler = () => {
    this.props.sortOptionHasChangedCallback(this.state.sortOption);
    this.setState({ modal14: false });
  }

  /**
   * Saves the value of the 'sortOption' field.
   *
   * @returns event - the sortOption field changed
   */
  handleChange = (event) => {
    this.setState({ sortOption: event.target.value });
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
  }
}

export default connect(mapStateToProps)(SortJobsites);
