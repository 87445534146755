/**
 * Calls Thingsboard API to get specified device's telemetry.
 *
 * @param {string} token - user JWT token
 * @param {string} id - device id
 * @param {string} keys - device id
 * @param {integer} startTs - start timestame in miliseconds
 * @param {integer} endTs - end timestame in miliseconds
 * @param {integer} limit - maximum amount of values to fetch
 */
export async function getTelemetry (token, id, keys, startTs, endTs,
  limit=100) {
    const url = `${process.env.REACT_APP_TB}/api/plugins/telemetry/DEVICE/${id}` +
      `/values/timeseries?keys=${keys}&startTs=${startTs}&endTs=${endTs}` +
      `&limit=${limit}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Authorization': `bearer ${token}`,
      },
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      return jsonResponse;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to get telemetry',
        detail: err
      };
      console.log(errormsg);
      return [];
    }
};

/**
 * Calls Thingsboard API to get specified device's telemetry keys.
 *
 * @param {string} token - user JWT token
 * @param {string} id - device id
 */
export async function getTelemetryTimeseriesKeys (token, id) {
  const url = `${process.env.REACT_APP_TB}/api/plugins/telemetry/DEVICE/${id}` +
    `/keys/timeseries`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get telemetry keys',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to get specified device's latest telemetry.
 *
 * @param {string} token - user JWT token
 * @param {string} id - device id
 */
export async function getLatestTelemetry (token, id) {
  const url = `${process.env.REACT_APP_TB}/api/plugins/telemetry/DEVICE/${id}` +
    `/values/timeseries`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get latest telemetry',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to get specified device attributes.
 *
 * @param {string} token - user JWT token
 * @param {string} id - device id
 */
export async function getAttributes (token, id, entityType) {
  const url = `${process.env.REACT_APP_TB}/api/plugins/telemetry/${entityType}` +
    `/${id}/values/attributes`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get device',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard's API to create/update coordinates attributes for
 *    specified entity.
 *
 * @param {string} token - user JWT token
 * @param {string} entityId - entity id
 * @param {string} entityType - entity type
 * @param {object} attributes - entity attributes to create/update
 */
export async function saveEntityCoordinates (token, entityId, entityType,
  coordinates) {
    const url = `${process.env.REACT_APP_TB}/api/plugins/telemetry/` +
      `${entityType}/${entityId}/attributes/SERVER_SCOPE`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': `bearer ${token}`,
      },
      body: JSON.stringify({
        latitude: coordinates.latitude,
        longitude: coordinates.longitude
      })
    });
    if (response.ok) {
      const okmsg = {
        message: 'Coordinates saved successfuly'
      };
      return okmsg;
    } else {
      const errormsg = {
        message: 'Error while trying to save coordinates'
      };
      console.log(errormsg);
      return errormsg;
    }
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      return jsonResponse;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to save attributes',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};
