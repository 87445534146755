const initialState = { commentId: ''}

function storeCommentId(state = initialState, action) {
  let nextState
  switch (action.type) {
      case 'SET_COMMENT_ID':
        nextState = { commentId:  action.value }
        return nextState || state
      default:
        return state
  }
}

export default storeCommentId;
