/**
 * Calls Thingsboard API to get specified entity contained entities.
 *
 * @param {string} token - user JWT token
 * @param {string} fromId - entity id
 * @param {string} fromType - entity type
 */
export async function findRelationByFrom (token, fromId, fromType) {
  const url = `${process.env.REACT_APP_TB}/api/relations?fromId=${fromId}` +
    `&fromType=${fromType}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get relations',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
}
