const initialState = { poiName: ''}

function storeActivePoIName(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_POI_NAME':
      nextState = { poiName:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeActivePoIName;
