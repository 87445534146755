import { combineReducers } from 'redux'
import storeUserId from './storeUserId.reducer'
import isLogged from './isLogged.reducer'
import storeToken from './storeToken.reducer';
import storeActiveJobsiteName from './storeActiveJobsiteName.reducer';
import storeActiveJobsiteId from './storeActiveJobsiteId.reducer';
import storeActiveRCId from './storeActiveRCId.reducer';
import storeActiveRCName from './storeActiveRCName.reducer';
import storeActivePoIId from './storeActivePoIId.reducer';
import storeActivePoIName from './storeActivePoIName.reducer';
import storeCoordinates from './storeCoordinates.reducer';
import storeAlarmLength from './storeAlarmLength.reducer';
import storeCoordinatesFixed from './storeCoordinatesFixed.reducer';
import storeCoordAnimation from './storeCoordAnimation.reducer';
import storePermission from './storePermission.reducer';
import storeCommentId from './storeCommentId.reducer';
import storePreviousPage from './storePreviousPage.reducer';
import storeActivePoIType from './storeActivePoIType.reducer';

export default combineReducers({
    storeUserId,
    isLogged,
    storeToken,
    storeActiveJobsiteName,
    storeActiveJobsiteId,
    storeActiveRCId,
    storeActiveRCName,
    storeActivePoIId,
    storeActivePoIName,
    storeCoordinates,
    storeAlarmLength,
    storeCoordinatesFixed,
    storeCoordAnimation,
    storePermission,
    storeCommentId,
    storePreviousPage,
    storeActivePoIType
})
