// # IMPORTS
import React from 'react';

// ## LOCAL IMPORTS
import store from '../../redux/store';

// # MAIN
/**
 * Calls Thingsboard API to get user's permissions and save them.
 */
export function getPermissionsPE() {
  fetch(`${process.env.REACT_APP_TB}/api/permissions/allowedPermissions`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${store.getState().storeToken.token}`,
    }
  }).then((res) => res.json())
  .then((res) => {
    if(res.userPermissions.genericPermissions.ALL[0] === 'ALL'){
      store.dispatch({type: 'SET_PERMISSIONS', value: true})
    } else{
      store.dispatch({type: 'SET_PERMISSIONS', value: false})
    }
  })
};

/**
 * Gets user type and set its permissions accordingly.
 *
 * @param {object} userInformations - user's informations
 */
export function getPermissionsCE(userInformations) {
  const userType = userInformations.scopes[0].split('_')[0].toLowerCase();
  if (userType === 'tenant') {
    store.dispatch({type: 'SET_PERMISSIONS', value: true})
  } else if (userType === 'customer') {
    store.dispatch({type: 'SET_PERMISSIONS', value: false})
  }
};
