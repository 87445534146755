// # IMPORTS
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as THREE from 'three';

// import { IFCLoader } from 'three/examples/jsm/loaders/IFCLoader.js'
import { IFCLoader } from "web-ifc-three/IFCLoader";

import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import SidePanel from '../../components/comments/sidePanel/SidePanel';
import TelemetrySidePanel
  from '../../components/telemetry/sidePanel/TelemetrySidePanel';
import ListPointsOfInterest
  from '../../components/pointsOfInterest/list/ListPointsOfInterest';
import EditRealityCapture
  from '../../components/realityCaptures/edit/EditRealityCapture';
import { postPointOfInterestPicture }
  from '../../services/api/pointsOfInterest/postPointOfInterest';
import store from '../../redux/store';
import './Details.css';

// # VARIABLES
const Potree = window.Potree
let viewer;

// # MAIN
/**
 * This class allows the rendering of the 'Details' page.
 */
class Details extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.potreeContainerDiv = React.createRef();
    this.state = {
      loading: false,
      coordinates: [],
      over: false,
      clic: true,
      fullScreen: false,
      freePointMeasurements: [],
      sidePanelIsOpen: false,
      telemetrySidePanelIsOpen: false,
      deviceName: '',
      deviceId: '',
      message: ''
    };
    window.setTelemetrySidePanel = this.setTelemetrySidePanel;
  }

  /**
   * Initiates the potree viewer and renders the reality capture.
   */
  componentDidMount = () => {
    let container = this.container;
    viewer = new Potree.Viewer(container);
    window.viewer = viewer;
    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1*1000*1000);
    viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    viewer.loadSettingsFromURL();
    viewer.setControls(viewer.earthControls);
    viewer.loadGUI(() => {
      viewer.setLanguage('en');
      viewer.toggleSidebar();
    });


    

    
    const url = `${process.env.REACT_APP_HOST}/reality-captures/` +
      `${this.props.rcId}/metadata.json ${this.props.token}`;
    Potree.loadPointCloud(url, 'Point Cloud').then(e => {
      let pointcloud = e.pointcloud;
      let material = pointcloud.material;
      material.activeAttributeName = 'rgba';
      material.minSize = 2;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE
      viewer.scene.addPointCloud(pointcloud);
      viewer.fitToScreen();
		}, e => console.err('ERROR: ', e));

    const ifcLoader = new IFCLoader();
    console.log(ifcLoader);
		ifcLoader.ifcManager.setWasmPath( '../../' );
		//ifcLoader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
    ifcLoader.ifcManager.applyWebIfcConfig({
      USE_FAST_BOOLS: true
      });
    
    /*ifcLoader.load( './model.ifc', function ( model ) {
        console.log(model)
					viewer.scene.scene.add( model);

				} );
*/
        viewer.scene.scene.add( new THREE.AmbientLight( 0x404040, 2.0 ) ); // soft white light );
        viewer.scene.scene.add( new THREE.DirectionalLight( 0xcccccc, 0.5 ) );
        const directional = new THREE.DirectionalLight( 0xcccccc, 0.5 );
    directional.position.z = 99999999999;
    viewer.scene.scene.add( directional );


  }

  /**
   * ???
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.fixedCoord !== prevProps.fixedCoord){
      this.setCoordinates();
    }
    if(this.props.poiId !== prevProps.poiId){
      this.setState({clic: true});
    }
  }

  /**
   * Sets the previous page name on component unmount.
   */
  componentWillUnmount = () => {
    this.setPreviousPage();
  }

  /**
   * Sets the Potree Viewer camera to zoom on the selected point of interest.
   *
   * @param {[float]} coordinates - point of interest coordinates
   */
  setCameraPosition = (coordinates) => {
    const cameraPosition = {
      x: parseFloat(coordinates.split(',')[0]) + 3,
      y: parseFloat(coordinates.split(',')[1]) + 3,
      z: parseFloat(coordinates.split(',')[2]) + 3,
    }
    const cameraTarget = {
      x: parseFloat(coordinates.split(',')[0]),
      y: parseFloat(coordinates.split(',')[1]),
      z: parseFloat(coordinates.split(',')[2]),
    }
    viewer.scene.view.position.set(cameraPosition.x, cameraPosition.y,
      cameraPosition.z);
    viewer.scene.view.lookAt(cameraTarget.x, cameraTarget.y,
      cameraTarget.z);
  }

  /**
   * Sets a dynamic style to the potree render area, depending on its
   *    'full screen' status.
   *
   * @returns {object} - dynamis css style
   */
  generatePotreeContainerStyle = () => {
    switch (this.state.fullScreen) {
      case true:
        return {position: 'absolute', width: '100%', height: '100%', top: '0'};
      case false:
        return { position: 'relative', width: '100%', height: '100%' };
    }
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <div style={{overflowX: 'none'}}>
        <Box sx={{ flexGrow: 1 }} style={{
          overflowY: 'scroll',
          overflowX: 'none',
          height: '95vh',
          marginTop: '20px'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Link onClick={() => {this.emptyPointId()}}
                className='back' component={RouterLink} to='/dashboard'
                underline='none'>
                  <ArrowBackIcon style={{fontSize: '50px'}}/>
              </Link>
            </Grid>
            <Grid item xs={10} className='sortContainer'>
              <div className='sortButton'>
                <div id='head'>
                  {this.props.rcName} {this.loadModif()}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{
                  height: '40em',
                  margin: '0 0 20px 0'
                }}
                id='potree-root'>
                <div className='potree_container'
                  style={this.generatePotreeContainerStyle()}>
                    <div
                      ref={(domNode) => { this.container = domNode; }}
                      id='potree_render_area'
                      style={{
                        position: 'relative',
                        width: '100vw',
                        height: '100%'
                      }}>
                      {this.renderFullScreenIcon()}
                    </div>
                    <div
                      id='potree_sidebar_container'
                      style={{top: '0'}}>
                    </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className='sortContainer'>
              {!this.state.fullScreen && this.renderPoIAndCommentsLists()}
            </Grid>
          </Grid>
        </Box>
        <SidePanel isOpen={this.state.sidePanelIsOpen}
          setSidePanelCallback={this.setSidePanel}
          entityId={this.props.poiId} entityType='DEVICE'
          entityName={this.props.poiName}/>
        <TelemetrySidePanel isOpen={this.state.telemetrySidePanelIsOpen}
          setSidePanelCallback={this.setTelemetrySidePanel}
          deviceName={this.state.deviceName}
          deviceId={this.state.deviceId}/>
      </div>
    )
  }

  /**
   * Renders the 'enter full screen' and 'exit full screen' icons above the
   *    Potree canvas.
   */
  renderFullScreenIcon = () => {
    if (!this.state.fullScreen) {
      return (
        <FullscreenIcon id='fullScreenIcon' onClick={() => {
          this.setState({fullScreen: true});
        }}/>
      )
    } else {
      return (
        <FullscreenExitIcon id='fullScreenIcon' onClick={() => {
          this.setState({fullScreen: false});
        }}/>
      )
    }
  }

  /**
   * Renders the points of interest associated to the active
   *    reality capture.
   */
  renderPoIAndCommentsLists = () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid className='item' item xs={12}>
            <div>
              <ListPointsOfInterest freePoints={this.state.freePointMeasurements}
                getPointCoordinatesCallback={this.getPointCoordinates}
                setSidePanelCallback={this.setSidePanel}
                generateAnnotationCallback={this.generateAnnotation}
                setTelemetrySidePanelCallback={this.setTelemetrySidePanel}
                setCameraPositionCallback={this.setCameraPosition}/>
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  /**
   * Renders the 'Edit reality capture' Modal.
   */
  loadModif = () => {
    if(this.props.permission === true){
      return(
        <EditRealityCapture/>
      )
    }
  }

  // ## MEASUREMENTS & ANNOTATIONS SERVICE
  /**
   * Gets point measurements from the Potree Scene.
   */
  getPointCoordinates = () => {
    let points = [];
    for (let e of viewer.scene.measurements) {
      if (e.name === 'Point') { points.push(e) };
    }
    this.setState({freePointMeasurements: points});
  }

  /**
   * Removes point measurement from the Potree Scene based on the associated
   *    point of interest's name.
   *
   * @param {string} name - point of interest name
   */
  removeMeasure = (name) => {
    const measurements = viewer.scene.measurements;
    measurements.forEach((item, i) => {
      if (item.name === name) {
        viewer.scene.removeMeasurement(item);
      }
    });
  }

  /**
   * Removes point measurement from the Potree Scene based on the
   *    measurement coordinates.
   *
   * @param {object} coordinates - point measurement coordinates
   */
  removeMeasureFromCoordinates = (coordinates) => {
    const measurements = viewer.scene.measurements;
    measurements.forEach((item, i) => {
      if (item.points[0].position.x.toFixed(2) ===
        parseFloat(coordinates.x).toFixed(2) &&
        item.points[0].position.y.toFixed(2) ===
        parseFloat(coordinates.y).toFixed(2) &&
        item.points[0].position.z.toFixed(2) ===
        parseFloat(coordinates.z).toFixed(2)) {
          viewer.scene.removeMeasurement(item);
        }
    });
  }

  /**
   * Generates a point measurement on the Potree Scene, using point of interest
   *    coordinates.
   *
   * @param {object} coordinates - point of interest coordinates
   * @param {string} name - point of interest name
   */
  generateMeasure = (coordinates, name) => {
    const position = new THREE.Vector3(parseFloat(coordinates.x),
      parseFloat(coordinates.y), parseFloat(coordinates.z));
    let p = new Potree.Measure();
    p.addMarker(position);
    p.name = name
    viewer.scene.addMeasurement(p)
  }

  /**
   * Generates an annotation on the Potree Scene, using point of interest
   *    coordinates.
   *
   * @param {object} coordinates - point of interest coordinates
   * @param {object} data - point of interest's data from ThingsBoard
   */
  generateAnnotation = (coordinates, data) => {
    let scene = viewer.scene;
    if (!this.checkIfAnnotationExists(data.name)) {
      const cameraPosition = {
        x: (parseFloat(coordinates.x) + 3),
        y: (parseFloat(coordinates.y) + 3),
        z: (parseFloat(coordinates.z) + 3)
      }
      const cameraTarget = {
        x: parseFloat(coordinates.x),
        y: parseFloat(coordinates.y),
        z: parseFloat(coordinates.z)
      }
      const title = `${data.name} <img src="https://potree.org/potree/build/` +
        `potree/resources/icons/goto.svg" name="action_set_scene" ` +
        `class="annotation-action-icon" style="filter: invert(1);"` +
        `onClick='window.setTelemetrySidePanel(true, "${data.name}", ` +
        `"${data.id.id}", "${data.type}");'>`;
      scene.annotations.add(new Potree.Annotation({
        position: [parseFloat(coordinates.x), parseFloat(coordinates.y),
          parseFloat(coordinates.z) + 2],
        title: title,
        cameraTarget: [cameraTarget.x, cameraTarget.y, cameraTarget.z],
        cameraPosition: [cameraPosition.x, cameraPosition.y, cameraPosition.z]
      }));
      this.removeMeasureFromCoordinates(coordinates);
      this.generateMeasure(coordinates, data.name);
    } else if (!this.checkAnnotationHasCorrectCoordinates(data.name, coordinates)){
      this.removeMeasure(data.name);
      this.removeMeasureFromCoordinates(coordinates);
      this.generateMeasure(coordinates, data.name);
      let annotations = viewer.scene.annotations.children;
      for (let item of annotations) {
        console.log(item);
        if (item._title.split(' ')[0] === data.name) {
          item.position.x = parseFloat(coordinates.x);
          item.position.y = parseFloat(coordinates.y);
          item.position.z = parseFloat(coordinates.z) + 2;
        }
      }
    }
  }

  /**
   * Checks if a point of interest's annotation already exists.
   *
   * @param {string} device - point of interest name
   */
  checkIfAnnotationExists = (device) => {
    let annotations = viewer.scene.annotations.children;
    for (let item of annotations) {
      if (item._title.split(' ')[0] === device) { return true; }
    }
    return false;
  }

  /**
   * Checks if an existing point of interest's annotation has the correct
   *    coordinates.
   *
   * @param {string} device - point of interest name
   * @param {object} coordinates - point of interest coordinates
   */
  checkAnnotationHasCorrectCoordinates = (device, coordinates) => {
    let annotations = viewer.scene.annotations.children;
    for (let item of annotations) {
      const itemCoordinates = {
        x: item.position.x,
        y: item.position.y,
        z: item.position.z
      }
      const numberCoordinates = {
        x: parseFloat(coordinates.x),
        y: parseFloat(coordinates.y),
        z: parseFloat(coordinates.z) + 2
      }
      if (itemCoordinates.x === numberCoordinates.x &&
        itemCoordinates.y === numberCoordinates.y &&
        itemCoordinates.z === numberCoordinates.z) {
          return true;
      }
    }
    return false;
  }

  // ## COMPONENT COMMUNICATION
  /**
   * Set the open state of the Side Panel.
   *
   * @param {boolean} status - the state of the Side Panel
   */
  setSidePanel = (status) => {
    this.setState({ sidePanelIsOpen: status });
  }

  /**
   * Set the open state of the Telemetry Side Panel.
   *
   * @param {boolean} status - the state of the Telemetry Side Panel
   */
  setTelemetrySidePanel = (status, deviceName, deviceId, deviceType) => {
    this.setState({
      telemetrySidePanelIsOpen: status,
      deviceName: deviceName,
      deviceId: deviceId
    });
    if (deviceType) {
      const action = { type: 'SET_POI_TYPE', value: deviceType }
      this.props.dispatch(action);
    }
  }

  // ## COORDINATES SERVICE
  /**
   * Saves point of interest coordinates.
   */
  setCoordinates = () => {
    const cc = this.coordinates();
    this.setState({coordinates: cc});
  }

  /**
   * Generates selected point of interest coordinates.
   *
   * @returns {[number]} [x, y, z] - point of interest coordinates
   */
  coordinates = () => {
    const c = this.props.fixedCoord;
    const arr = c.split(',');
    const xS = arr[0];
    const yS = arr[1];
    const zS = arr[2];
    const x = Number(xS);
    const y = Number(yS);
    const z = Number(zS);
    return [x, y, z];
  }

  // ## REDUX
  /**
   * Saves the page name as the previous page.
   */
  setPreviousPage = () => {
    const action = { type: 'SET_PREVIOUS_PAGE', value: 'details' }
    this.props.dispatch(action);
  }

  /**
   * Empties store of point of interest informations.
   */
  emptyPointId = () => {
      let action = { type: 'SET_POI_ID', value: '' }
      this.props.dispatch(action);

      action = { type: 'SET_COORDINATES', value: ''};
      this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    poiName, coordinates, fixedCoord, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    poiName: state.storeActivePoIName.poiName,
    coordinates: state.storeCoordinates.coordinates,
    fixedCoord: state.storeCoordinatesFixed.fixedCoord,
    permission: state.storePermission.allow,
  }
}

export default connect(mapStateToProps)(Details);
