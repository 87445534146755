// # IMPORTS
import React from 'react';
import { Button } from '@bbri/ui';
import { connect } from 'react-redux';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import { postPointOfInterestRCRelation }
  from '../../../services/api/pointsOfInterest/postPointOfInterest';
import { putPointOfInterestCoordinates }
  from '../../../services/api/pointsOfInterest/putPointOfInterest';
import { getPointsOfInterestFromJobsiteId }
  from '../../../services/api/pointsOfInterest/getPointsOfInterest';
import { getAlarms } from '../../../services/thingsboard/alarms';
import store from '../../../redux/store';
import './AddPointOfInterest.css';

// # MAIN
/**
 * This class allows the rendering of an 'add point of interest' dialog.
 *
 * @param {[object]} freePoints - user generated Potree point measurements
 * @param {function} getPointCoordinatesCallback - callback for when the dialog
 *    opens. Allows the component to get user generated Potree point
 *    measurements
 * @param {function} pointOfInterestWasCreatedCallback - callback for when a
 *    point of interest is created
 */
class AddPointOfInterest extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      loading: false,
      message: '',
      coordinates: '',
      severity: null,
      devices: [],
      deviceName: '',
      deviceId: '',
      deviceType: '',
      deviceDescription:'',
      alarms:[],
      freePointsCoordinates: [],
      coordinatesArray: [],
    }
  }

  /**
   * Loads the points of interest on component mount.
   */
  componentDidMount = () => {
    this.getPoIFromJobsiteId();
  }

  /**
   * Get the list of alarms for the active point of interest.
   * Saves the list of user generated Potree point measurements.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.state.deviceId !== prevState.deviceId){
      this.getAlarms(this.state.deviceId);
    }
    if (this.props.freePoints !== prevProps.freePoints) {
      let freePointsCoordinates = []
      for (let point of this.props.freePoints) {
        freePointsCoordinates.push(point.points[0].position);
      }
      this.setState({freePointsCoordinates: freePointsCoordinates})
    }
  }

  /**
   * Toggles the 'add point of interest' popup and loads measurements.
   *
   * @param {integer} nr - the modal number
   */
  toggle = nr => () => {
    this.props.getPointCoordinatesCallback();
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: '',
      deviceName: '',
      coordinates: ''
    });
  }

  /**
   * Rouds a float to two decimals.
   *
   * @param {float} num - float to be rounded
   */
  roundToTwo = (num) => {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <AddIcon className='iPlus'
          style={{ margin: '5px' }}
          onClick={this.toggle(14)}/>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}>
          <DialogTitle>Add point of interest</DialogTitle>
          <DialogContent>
            <form onSubmit={preventDefault}>
              <FormControl variant='standard' fullWidth>
                <InputLabel id="sortJobsitesSelectLabel">Device</InputLabel>
                <Select
                  id='poiCoordinatesSelect'
                  labelId='poiCoordinatesSelectLabel'
                  value={this.state.deviceName}
                  onChange={this.handleDevice}
                >
                  {this.listDevices()}
                </Select>
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel id="sortJobsitesSelectLabel">Coordinates</InputLabel>
                <Select
                  id='sortJobsitesSelect'
                  labelId='sortJobsitesSelectLabel'
                  value={this.state.coordinates}
                  onChange={this.handleCoordinates}
                >
                  {this.renderCoordinates()}
                </Select>
              </FormControl>
              <div className='msg'>
                {loadingRender(this.state.loading, this.state.message)}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary' children='Close'
              onClick={this.toggle(14)}>
            </Button>
            <Button variant='primary' children='Save'
              onClick={this.submitHandler}>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  /**
   * Renders the user generated Potree point measurements coordinates as
   *    select menu items.
   */
  renderCoordinates = (coordinates) => {
    const data = this.state.freePointsCoordinates;
    let items = [];
    for (const [value] of data.entries()) {
      let x = this.roundToTwo(data[value].x);
      let y = this.roundToTwo(data[value].y);
      let z = this.roundToTwo(data[value].z);
      items.push(
        <MenuItem value={`${x} / ${y} / ${z}`} key={value}
          onClick={()=> {
            this.setState({
              coordinatesArray: `${x},${y},${z}`
            });
          }}>
            {`${x} / ${y} / ${z}`}
        </MenuItem>

      );
    }
    items.map( (item) => {
      return items
    })
    return items;
  }

  /**
   * Renders a list of devices.
   *
   * @returns {[JSX]} items - list of devices
   */
  listDevices = () => {
    const data = this.state.devices;
    let items = [];
    for (const [value] of data.entries()) {
      items.push(
        <MenuItem value={data[value].name} key={value}
          onClick={()=> {
            this.setState({
              deviceName: data[value].name,
              deviceId: data[value].id.id,
            });
          }}>
            {data[value].name}
        </MenuItem>
      );
    }
    items.map( (item) => {
      return items
    })
    return items;
  }

  // ## ALARM SERVICE
  /**
   * Saves alarm length.
   *
   * @param {integer} length - alarm length
   */
  setAlarmLength = (length) => {
    const action12 = { type: 'SET_ALARM_LENGTH', value: length};
    this.props.dispatch(action12);
  }

  /**
   * Calls Thingsboard API to get specified device's alarms.
   *
   * @param {string} id - device id
   */
  getAlarms = async (id) => {
    this.setState({loading: true});
    const alarms = await getAlarms(this.props.token, 'DEVICE', id);
    this.setState({
      alarms: alarms,
      loading: false,
    });
  };

  // ## SEVERITY SERVICE
  /**
   * Calculates the mean severity of the active point of interest.
   *
   * @returns {???} ???
   */
  calculSeverity = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let tabSev = [];
    let tabInt = [];
    let alarms = this.state.alarms;
    if(alarms.length !== 0){
      alarms.forEach(e => tabSev.push(e.severity));
      tabSev.forEach(e => tabInt.push(this.sevToInt(e)));
      return tabInt.reduce(reducer);
    } else{
      tabInt.push(0);
      return tabInt.reduce(reducer);
    }
  }

  /**
   * Gives an integer severity value to a string.
   *
   * @param {string} sev - point of interest severity ???
   * @returns {integer} - integer severity value
   */
  sevToInt = (sev) => {
    if(sev === 'CRITICAL'){
      return 8;
    } else if(sev === 'MAJOR'){
      return 5;
    } else if(sev === 'MINOR'){
      return 3;
    } else if(sev === 'WARNING'){
      return 2;
    } else if(sev === 'INDETERMINATE'){
      return 1;
    } else {return 0}
  }

  // ## POINT OF INTEREST SERVICE
  /**
   * Get user points of interest associated to active jobsite
   */
  getPoIFromJobsiteId = async () => {
    this.setState({loading: true});
    const pointsOfInterest = await getPointsOfInterestFromJobsiteId(
      this.props.token, this.props.jobsiteId);
    this.setState({
      devices: pointsOfInterest,
      loading: false,
    });
  };

  // ## FORM SERVICE
  /**
   * Calls the postCom function.
   *
   * @param event - form submission
   */
   submitHandler = async (event) => {
     if(this.state.coordinates === '' || this.state.deviceName === '') {
       alert('NO COORDINATES OR NO DEVICE' + '\n' +
         'Verify if you clicked on the model or if you chose a device');
     } else {
       this.setState({loading: true});
       let response = await postPointOfInterestRCRelation(this.props.token,
         this.state.deviceId, this.props.rcId);
       this.setState({
         message: response.message
       });
       this.setState({loading: true});
       response = await putPointOfInterestCoordinates(this.props.token,
         this.state.deviceId, this.state.coordinatesArray, this.props.rcId);
       this.setState({
         message: response.message,
         loading: false,
         modal14: !this.state.modal14
       });
       this.props.pointOfInterestWasCreatedCallback();
     }
   }

   /**
    * Saves point of interest coordinates.
    *
    * @param event - change of coordinates field value
    */
  handleCoordinates = (event) => {
    this.setState({ coordinates : event.target.value });
  }

  /**
   * Saves point of interest device id.
   *
   * @param event - change of device field value
   */
  handleDevice = (event) => {
    this.setState({ deviceName : event.target.value });
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId, alarmLength, coordinates -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    alarmLength: state.storeAlarmLength.alarmLength,
    coordinates: state.storeCoordinates.coordinates,
  }
}

export default connect(mapStateToProps)(AddPointOfInterest);
