// # IMPORTS
import React, { useState } from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';
import 'react-sliding-side-panel/lib/index.css';
import moment from 'moment';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// ## LOCAL IMPORTS
import { getLatestTelemetry, getAttributes }
  from '../../../services/thingsboard/telemetry';
import store from '../../../redux/store';
import './TelemetrySidePanel.css';

// # THEME
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid rgba(0, 191,182)',
  color: 'black',
  width: '80%'
}));

// # MAIN
/**
 * This class allows the rendering of a right sided side panel for entity
 *    telemetry and pictures.
 *
 * @param {boolean} isOpen - the side panel is open
 * @param {function} setSidePanelCallback - callback for when the side panel's
 *    state changes
 * @param {string} deviceName - deviceName
 * @param {string} deviceId - deviceId
 */
class TelemetrySidePanel extends React.Component {
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: [],
      content: '',
      modal: false,
      id: '',
      delmsg:'',
      latestTelemetry: [],
      attributes: []
    };
  }

  /**
   * Sets the '_isMounted' variable to true on component mount.
   */
  componentDidMount = () => {
    this._isMounted = true;
  }

  /**
   * Gets device's latest telemetry and attributes on component update.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.deviceId !== prevProps.deviceId && this.props.deviceId) {
      this.getLatestTelemetry();
      this.getAttributes();
    }
  }

  /**
   * Sets the '_isMounted' variable to false on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <SlidingPanel
        type='right'
        isOpen={this.props.isOpen}
        size='80'
        panelClassName='sidePannelContent'
        panelContainerClassName='sidePannel'
        backdropClicked={() => this.props.setSidePanelCallback(false)}
      >
        <p className='title'>Telemetry for '{this.props.deviceName}'</p>
        <h1 className='center' style={{marginBottom: '10px'}}>
          Latest Telemetry
        </h1>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {this.renderLatestTelemetry()}
            <Grid item xs={12}>
              <div className='center'>
                <Link component={RouterLink} to='/telemetry'
                  underline='none' className='whiteLink'>
                    <div className='center'>
                      <Button
                        onClick={() => {
                          this.setDeviceId(this.props.deviceId);
                          this.setDeviceName(this.props.deviceName);
                        }}>
                        detailed telemetry
                      </Button>
                    </div>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Box>
        <h1 className='center' style={{margin: '10px 0 10px 0'}}>Pictures</h1>
        {this.renderPictures()}
      </SlidingPanel>
    );
  }

  /**
   * Renders the device's pictures in a slide show.
   */
  renderPictures = () => {
    let slideImages = [];
    for (let item of this.state.attributes) {
      if (item.key === 'pictureUrl') {
        for (let url of item.value) {
          slideImages.push({
            url: url,
            caption: item.key
          })
        }
      }
    }
    return (
      <div className="slide-container center" style={{
        marginBottom: '50px'
      }}>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div className="each-slide" key={index}>
              <img className='largePictures' src={slideImage.url}></img>
            </div>
          ))}
        </Slide>
      </div>
    )
  }

  /**
   * Renders the device's latest telemetry.
   */
  renderLatestTelemetry = () => {
    let data = this.state.latestTelemetry;
    let attributes = {};
    for (let attribute of this.state.attributes) {
      attributes[attribute.key] = attribute;
    }
    let items = [];
    if (this.props.deviceType === 'CONCRETE_MATURITY' && data.temperature &&
      attributes.fcThreshold) {
        return (
          <>
            <Grid item xs={12}>
              <Item className='center'>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    Concrete Temperature
                  </Grid>
                  <Grid item xs={2}>
                    {Number(data.temperature[0].value).toFixed(1)}
                  </Grid>
                  <Grid item xs={6}>
                    {moment(data.temperature[0].ts).fromNow()}
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className='center'>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    Concrete Strength
                  </Grid>
                  <Grid item xs={2}>
                    {Number(data.fc[0].value).toFixed(1)}
                  </Grid>
                  <Grid item xs={6}>
                    {moment(data.fc[0].ts).fromNow()}
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item className='center'>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    Concrete Strength Threshold
                  </Grid>
                  <Grid item xs={2}>
                    {Number(attributes.fcThreshold.value).toFixed(1)}
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Item>
            </Grid>
          </>
        )
    }
    for (const key of Object.keys(data)) {
        items.push(
          <Grid item xs={12} key={key}>
            <Item className='center'>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {key}
                </Grid>
                <Grid item xs={2}>
                  {data[key][0].value}
                </Grid>
                <Grid item xs={6}>
                  {moment(data[key][0].ts).fromNow()}
                </Grid>
              </Grid>
            </Item>
          </Grid>
        );
    }
    return items;
  }

  // ## TELEMETRY SERVICE
  /**
   * Gets device's attributes from ThingsBoard API.
   */
  getAttributes = async () => {
    const attributes = await getAttributes(this.props.token, this.props.deviceId,
      'DEVICE');
    this.setState({
      attributes: attributes
    });
  }
  /**
   * Gets device's latest telemetry from ThingsBoard API.
   */
  getLatestTelemetry = async () => {
    const response = await getLatestTelemetry(this.props.token,
      this.props.deviceId);
    this.setState({latestTelemetry: response});
  }

  // ## REDUX
  /**
   * Saves reality capture id.
   *
   * @param {string} id - reality capture id
   */
  setDeviceId = (id) => {
    const action = { type: 'SET_POI_ID', value: id }
    this.props.dispatch(action);
  }

  /**
   * Saves reality capture name.
   *
   * @param {string} name - reality capture name
   */
  setDeviceName = (name) => {
    const action = { type: 'SET_POI_NAME', value: name }
    this.props.dispatch(action);
  }
};

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId,
 *    rcName, deviceType - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    deviceType: state.storeActivePoIType.poiType
  }
}

export default connect(mapStateToProps)(TelemetrySidePanel);
