/**
 * Creates a reality capture.
 * POST /reality-captures/
 *
 * @param data - request body
 */
const postRealityCapture = async (token, data) => {
  const url = `${process.env.REACT_APP_HOST}/reality-captures`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`
    },
    body: data,
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to add the reality capture',
      detail: err
    };
    console.log(errormsg);
    return errormsg
  }
};

export default postRealityCapture;
