/**
 * Creates a jobsite.
 * POST /jobsites/
 *
 * @param {string} token - user JWT token
 * @param {string} name - jobsite name
 * @param {array} coordinates - jobsite coordinates
 */
const postJobsite = async (token, name, coordinates) => {
    const url = `${process.env.REACT_APP_HOST}/jobsites`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      },
      body: JSON.stringify({
        name: name,
        latitude: coordinates[0],
        longitude: coordinates[1]
      }),
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      if (jsonResponse[0] && jsonResponse[0].msg) { throw jsonResponse; }
      const responsemsg = {
        message: 'Jobsite added with success',
        detail: jsonResponse
      };
      return responsemsg;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to create jobsite',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};

export default postJobsite;
