const initialState = { alarmLength: []}

function storeAlarmLength(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_ALARM_LENGTH':
      nextState = { alarmLength:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeAlarmLength;
