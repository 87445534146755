// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';

// ## MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import Logout from '../logout/Logout';
import store from '../../redux/store';
import logo from '../../assets/BW.png';
import smallLogo from '../../assets/BW_short.png';
import './NavBar.css';

// ## VARIABLES
const pages = ['about', 'contact'];
const settings = ['Sign Out'];

// # MAIN
/**
 * This class allows the rendering of a Nav Bar, visible from any of the app's
 *    pages.
 *
 * @param {boolean} isLoggedIn - the user is logged in
 */
class NavBar extends React.Component {
  /**
   * Initiates the component's state.
   */
  state = {
    isOpen: false,
    anchorElNav: null,
    anchorElUser: null
  };

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <AppBar sx={{ bgcolor: "#ffffff" }} position="static">
        <Container maxWidth="false">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(this.state.anchorElNav)}
                onClose={this.handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={this.handleCloseNavMenu}
                    component={RouterLink} to={`/${page}`}>
                      <Typography className='blackLink'>{page}</Typography>
                  </MenuItem>
                ))}
                <MenuItem hidden={!this.props.isLoggedIn} key={'signout'}
                  onClick={this.handleCloseNavMenu}
                  component={RouterLink} to='/'>
                    <Logout/>
                </MenuItem>
                <MenuItem hidden={this.props.isLoggedIn} key={'signup'}
                  onClick={this.handleCloseNavMenu}
                  component={RouterLink} to='/signup'>
                    <Typography className='blackLink'>Sign Up</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none',
              flexDirection: 'row-reverse' } }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                >
                  <Link component={RouterLink} to='/home'
                    underline='none'>
                      <img src={smallLogo} alt='logo'
                        style={{marginRight: '6px', height : '20px'}}/>
                  </Link>
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
              >
                <Link component={RouterLink} to='/home'
                  underline='none'>
                    <img src={logo} alt='logo'
                      style={{marginRight: '6px', height : '40px'}}/>
                </Link>
              </Typography>
              {pages.map((page) => (
                <Button className='whiteLink'
                  key={page}
                  component={RouterLink} to={`/${page}`}
                  sx={{color: 'white'}}
                >
                  <div className='whiteLink'>{page}</div>
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }}}>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                    <PersonIcon className='whiteIcon'/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={this.state.anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(this.state.anchorElUser)}
                onClose={this.handleCloseUserMenu}
              >
                <MenuItem hidden={!this.props.isLoggedIn} key={'signout'}
                  onClick={this.handleCloseUserMenu}
                  component={RouterLink} to='/'
                  className='blackLink'>
                    <Logout/>
                </MenuItem>
                <MenuItem hidden={this.props.isLoggedIn} key={'signup'}
                  onClick={this.handleCloseUserMenu}
                  component={RouterLink} to='/signup'
                  className='blackLink'>
                    <Typography>Sign Up</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  // ## MENU SERVICE
  /**
   * Opens the 'hamburger' menu (only visible for xs devices).
   */
  handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorElNav: event.currentTarget});
  };

  /**
   * Opens the 'person' menu (only visible for md devices and bigger).
   */
  handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorElUser: event.currentTarget});
  };

  /**
   * Closes the 'hamburger' menu (only visible for xs devices).
   */
  handleCloseNavMenu = () => {
    this.setState({anchorElNav: null});
  };

  /**
   * Closes the 'person' menu (only visible for md devices and bigger).
   */
  handleCloseUserMenu = () => {
    this.setState({anchorElUser: null});
  };
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name
  }
}

export default connect(mapStateToProps)(NavBar)
