// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Surface, Symbols , ReferenceArea, ReferenceLine, Brush}
  from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import { Button } from '@bbri/ui';

// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

// ## LOCAL IMPORTS
import { getTelemetry, getAttributes }
  from '../../../../services/thingsboard/telemetry';
import store from '../../../../redux/store';
import './ConcreteMaturity.css';

// # THEME
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid rgba(0, 191,182)',
  color: 'black',
  width: '80%'
}));

// # MAIN
/**
 * This class allows the rendering of a customized area chart for devices of
 *    'CONCRETE_MATURITY' type.
 *
 * @param {integer} startTs - start timestamp for telemetry data fetch from
 *    ThingsBoard API
 * @param {integer} endTs - end timestamp for telemetry data fetch from
 *    ThingsBoard API
 * @param {[string]} lineDataKeys - array of data keys
 * @param {integer} limit - maximum amount of telemetry data to fetch from
 *    ThingsBoard API
 */
class ConcreteMaturity extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      initialData: [],
      data: [],
      attributes: [],
      disabled: [],
      chartColors: {},
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: 'dataMax',
      bottom: 'dataMin',
      animation: true
    };
  }

  /**
   * Gets selected device's telemetry on component mount.
   */
  componentDidMount = () => {
    this.getTelemetry();
    this.getAttributes();
  }

  /**
   * Gets selected device's telemetry and attributes on component update.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.lineDataKeys !== prevProps.lineDataKeys ||
      this.props.colors !== prevProps.colors ||
      this.props.startTs !== prevProps.startTs){
        this.getTelemetry();
        this.getAttributes();
    }
  }

  /**
   * Disables data display for clicked key in the chart legend.
   *
   * @param {string} dataKey - chart legend key
   */
  handleClick = dataKey => {
    if (_.includes(this.state.disabled, dataKey)) {
      this.setState({
        disabled: this.state.disabled.filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ disabled: this.state.disabled.concat(dataKey) });
    }
  };

  /**
   * Sets appropriate area colors for each data key.
   */
  generateChartColorsArray = () => {
    let chartColors = {};
    for (let i in this.props.lineDataKeys) {
      chartColors[this.props.lineDataKeys[i]] = this.props.colors[i];
    }
    this.setState({ chartColors: chartColors});
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
   render = () => {
  	  const {
       refAreaLeft,
       refAreaRight,
  		} = this.state;
     let attributes = {};
     for (let attribute of this.state.attributes) {
       attributes[attribute.key] = attribute;
     }
     if (this.state.data.length === 0) {
       return (<h1 className='center'>No data available</h1>)
     } else {
       return (
         <>

           <div className='highlight-bar-charts' style={{
             userSelect: 'none',
             width: '100%',
             height: '100%',
             marginTop: '60px'
           }}>
             <ResponsiveContainer width='100%' height='80%'>
               <AreaChart data={this.state.data} margin={{
                   top: 5, right: 30, left: 0, bottom: 5,
               }}
               >
                 <CartesianGrid strokeDasharray='5 5'/>
                 <XAxis
                 dataKey='ts'
         				type="number"
         				domain={['auto', 'auto']}
         				//domain={['dataMin', 'dataMax']}
         				scale = 'linear'
                   tickFormatter={ts => moment(ts).format('MMMM Do, h:mm:ss a')}/>
                 <YAxis/>
                 <Tooltip labelFormatter={
                   ts => moment(ts).format('MMMM Do YYYY, h:mm:ss a')
                 }/>
                 {_.toPairs(this.state.chartColors)
                 .filter(pair => !_.includes(this.state.disabled, pair[0]))
                 .map(pair => (
                   <Area key={pair[0]} type='monotone' dataKey={pair[0]}
                     stroke={pair[1]} fill={pair[1]} connectNulls={true}/>
                 ))}
                 <ReferenceLine y={attributes.fcThreshold.value}
                   label='Concrete Strength Threshold'
                   stroke='#f1ab57'
                   strokeWidth={3}/>
                   <Brush
           				dataKey='ts'
           				tickFormatter={ts => moment(ts).format('MMMM Do YYYY, h:mm a')}
           				/>
               </AreaChart>
             </ResponsiveContainer>
             <div>
               {this.renderCusomizedLegend()}
             </div>
           </div>
           <h2 className='center' style={{
             margin: '20px 0 20px 0'
           }}>Attributes</h2>
           {this.renderAttributes()}
         </>
       )
     }
   };

   /**
    * Dynamically renders a customised and clickable legend, depending on the
    *    data fetched from ThingsBoard.
    */
   renderCusomizedLegend = () => {
     let payload = _.toPairs(this.state.chartColors).map(pair => ({
       dataKey: pair[0],
       color: pair[1]
     }))
     return (
       <div className='customized-legend center'>
         {payload.map(entry => {
           const { dataKey, color } = entry;
           const active = _.includes(this.state.disabled, dataKey);
           const style = {
             marginRight: 10,
             color: active ? '#AAA' : '#000'
           };

           return (
             <span key={dataKey}
               className='legend-item'
               onClick={() => this.handleClick(dataKey)}
               style={style}
             >
               <Surface width={10} height={10} viewBox='0 0 10 10'>
                 <Symbols cx={5} cy={5} type='circle' size={50} fill={color} />
                 {active && (
                   <Symbols
                     cx={5}
                     cy={5}
                     type='circle'
                     size={25}
                     fill={'#FFF'}
                   />
                 )}
               </Surface>
               <span>{dataKey === 'fc' ? 'concrete strength' : dataKey}</span>
             </span>
           );
         })}
       </div>
     );
   };

   /**
    * Renders device's useful attributes.
    */
   renderAttributes = () => {
     let attributes = {};
     for (let attribute of this.state.attributes) {
       attributes[attribute.key] = attribute;
     }
     return (
       <Box sx={{ flexGrow: 1 }}>
         <Grid container spacing={2}>
           <Grid item xs={12}>
             <Item className='center'>
               <Grid container spacing={2}>
                 <Grid item xs={6}>
                   Cement type
                 </Grid>
                 <Grid item xs={6}>
                   {attributes.CementType.value}
                 </Grid>
               </Grid>
             </Item>
           </Grid>
           <Grid item xs={12}>
             <Item className='center'>
               <Grid container spacing={2}>
                 <Grid item xs={6}>
                   Concrete strength class
                 </Grid>
                 <Grid item xs={6}>
                   {attributes.fck.value}
                 </Grid>
               </Grid>
             </Item>
           </Grid>
           <Grid item xs={12}>
             <Item className='center'>
               <Grid container spacing={2}>
                 <Grid item xs={6}>
                   Concrete casting time
                 </Grid>
                 <Grid item xs={6}>
                   {moment(attributes.ConcreteCastingTime.value * 1000)
                     .format('MMMM Do YYYY, h:mm:ss a')}
                 </Grid>
               </Grid>
             </Item>
           </Grid>
         </Grid>
       </Box>
     )
   }

   // ## TELEMETRY SERVICE
   /**
    * Gets telemetry data from ThingsBoard API.
    */
  getTelemetry = async () => {
    const response = await getTelemetry(this.props.token, this.props.poiId,
      this.props.lineDataKeys.join(','), this.props.startTs, this.props.endTs,
      this.props.limit);
    this.generateChartColorsArray();
    this.generateData(response);
  }

  /**
   * Gets device's attributes from ThingsBoard API.
   */
  getAttributes = async () => {
    const response = await getAttributes(this.props.token, this.props.poiId,
      'DEVICE');
    this.setState({
      attributes: response
    });
  }

  /**
   * Formats ThingsBoard data to be readable by Recharts.
   *
   * @param {object} response - ThingsBoard API response
   */
  generateData = (response) => {
    let data = [];
    let chartColors = {};
    for (let key of Object.keys(response)) {
      for (let keyData of response[key]) {
        let found = false;
        for (let i in data) {
          if (data[i].ts === keyData.ts) {
            data[i][key] = parseFloat(keyData.value);
            found = true;
            break;
          }
        }
        let date;
        let object = {};
        if (!found) {
          object.ts = keyData.ts;
          object[key] = parseFloat(keyData.value);
          data.push(object);
        }
      }
    }
    data.sort(function (a, b) { return a.ts - b.ts; });
    this.setState({
      initialData: data,
      data: data
    });
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    coordinates, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    coordinates: state.storeCoordinates.coordinates,
    permission: state.storePermission.allow
  }
}

export default connect(mapStateToProps)(ConcreteMaturity);
