// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';

// ## LOCAL IMPORTS
import logout from '../../services/thingsboard/logout';
import store from '../../redux/store';
import './Logout.css'

// # MAIN
/**
 * This class allows the rendering of a 'Log Out' button.
 */
class Logout extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return(
      <div  className='blackLink' onClick={this.logout}>log out</div>
    )
  }

  // ## LOG OUT SERVICE
  /**
   * Calls Thingsboard API to log user out.
   */
  logout = async () => {
    const response = await logout(this.props.token);
    if (response.ok) {
      this.setLogout();
    } else {
      const errormsg = {
        message: 'Error while trying to log user out',
        detail: ''
      };
      console.log(errormsg);
    }
  };

  // ## REDUX
  /**
   * Empties store of user informations.
   */
  setLogout = () => {
    let action = { type: 'LOGIN', value: false };
    this.props.dispatch(action);

    action = { type: 'SET_TOKEN', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_ID', value: null};
    this.props.dispatch(action);

    action = { type: 'SET_RC_ID', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_JOBSITE_NAME', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_JOBSITE_ID', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_RC_NAME', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_POI_ID', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_COORDINATES', value: ''};
    this.props.dispatch(action);

    action = { type: 'SET_ALARM_LENGTH', value: null};
    this.props.dispatch(action);

    action = { type: 'SET_PERMISSION', value: false};
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token
  }
}

export default connect(mapStateToProps)(Logout);
