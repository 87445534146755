/**
 * Deletes a reality capture.
 * DELETE /reality-captures/:realityCaptureid/
 *
 * @param {string} token - user JWT token
 * @param {string} id - reality capture id
 */
const deleteRealityCapture = async (token, id) => {
  const url = `${process.env.REACT_APP_HOST}/reality-captures/${id}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to add the reality capture',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

export default deleteRealityCapture;
