const initialState = { jobsiteId: ''}

function storeActiveJobsiteId(state = initialState, action) {
  let nextState
  switch (action.type) {
      case 'SET_JOBSITE_ID':
        nextState = { jobsiteId:  action.value }
        return nextState || state
      default:
        return state
  }
}

export default storeActiveJobsiteId;
