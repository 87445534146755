const initialState = { fixedCoord: ''}

function storeCoordinatesFixed(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_COORDINATES_FIXED':
      nextState = { fixedCoord:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeCoordinatesFixed;
