// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

// ## LOCAL IMPORTS
import Details from '../pages/details/Details';
import store from '../redux/store';

// # MAIN
/**
 * Checks for loggedIn user state and eventually redirects to the appropriate
 *    page.
 */
const AuthRoutes = props => {
  const {type} = props;
  if (type === 'guest' && store.getState().isLogged.isLoggedIn) {
    return <Redirect to='/home' />;
  } else if (type === 'private' && !store.getState().isLogged.isLoggedIn) {
    return <Redirect to='/welcome' />;
  } else {
    return <Route {...props}/>;
  }
};

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token
  }
}

export default connect(mapStateToProps)(AuthRoutes);
