// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Paper from '@mui/material/Paper';

// ## LOCAL IMPORTS
import AddRealityCapture from '../add/AddRealityCapture';
import DeleteRealityCapture from '../delete/DeleteRealityCapture';
import ListAlarms from '../../alarms/ListAlarms';
import { getRealityCapturesFromJobsiteId }
  from '../../../services/api/realityCaptures/getRealityCaptures';
import deleteRealityCapture
  from '../../../services/api/realityCaptures/deleteRealityCapture';
import { getAllAlarms } from '../../../services/thingsboard/alarms';
import store from '../../../redux/store';
import './ListRealityCaptures.css';

// # THEME
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid rgba(0, 135,183, 1)',
  width: '80%'
}));

// # MAIN
/**
 * This class allows the rendering of a list of reality captures for the
 *    active jobsite.
 *
 * @param {string} sortOption - option on which the list should be sorted
 * @param {function} setSidePanelCallback - callback for when the comments
 *    side panel is opened
 */
class ListRealityCaptures extends React.Component{
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      coordinates: [],
      modal: false,
      id: '',
      delmsg:'',
      rcBellIcons: {}
    };
  }

  /**
   * Loads the reality captures on component mount.
   */
  componentDidMount = () => {
    this._isMounted = true;
    this.getRC();
  }

  /**
   * Refreshes the reality capture list when the active jobsite changes.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.sortOption !== prevProps.sortOption){
      this.getRC();
    }
  }

  /**
   * Unsets the '_isMounted' variable on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  /**
   * Toggles the reality capture deletion warning.
   */
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    if(this.state.data.length === 0){
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid className='item' item xs={12}
              style={{paddingTop: '50px'}}>
                {this.renderAjout()}
            </Grid>
            <Grid className='item' item xs={12}>
              <h6 id='selectjs' className='co'>No model available</h6>
            </Grid>
          </Grid>
        </Box>
      )
    }
    else{
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid className='item' item xs={12}
                style={{paddingTop: '50px'}}>
                  {this.renderAjout()}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {this.listPlans3D()}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <DeleteRealityCapture isOpen={this.state.modal}
            rcWasDeletedCallback={this.getRC}/>
        </>
      )
    }
  }

  /**
   * Renders the 'add reality capture' modal.
   */
   renderAjout = () => {
     if(this.props.permission === true) {
       return  (
         <>
           <AddRealityCapture rcWasCreatedCallback={this.getRC}/>
         </>
       )
     } else {
       return <h6></h6>
     }
   }

  /**
   * Renders the trash icon next to each reality capture name.
   *
   * @param {string} id - reality capture id
   * @param {string} msg - ???
   */
  loadIcon = (id, msg) => {
    if(this.props.permission === true){
      return (
        <DeleteForeverIcon className='trash'
          onClick={() => {
            this.toggle();
            this.setState(id);
            this.setState(msg);
            this.setPlanId(id.id);
          }}
        />
      )
    }
  }

  /**
   * Creates a list of every user's reality capture associated with
   *    selected jobsite.
   *
   * @returns {[JSX]} items - list of reality captures
   */
  listPlans3D = () => {
    const data = this.state.data;
    const items =[];
    for (const [value] of data.entries()) {
      if (data[value].id.id === this.state.id) {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={`rc${value}`}
              onClick={ () => { this.setState({id: data[value].id.id}); }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9} style={{color: 'white'}}>
                    <h3 className='name'>
                      {this.renderBellIconButton(
                        this.state.rcBellIcons[data[value].id.id],
                        data[value].name)}
                      {' '}
                      {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                    </h3>
                  </Grid>
                  <Grid item xs={3} style={{color: 'white'}}>
                    {this.dateString(data[value].attributes.date)}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 className='name'>Actions</h3>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div style={{
                            margin: 'auto',
                            width: '80%',
                            padding: '10px'
                          }}>
                            <Link component={RouterLink} to='/details'
                              underline='none' className='whiteLink'>
                                <div className='center'>
                                  <Button  variant='secondary' onClick={() => {
                                    this.setPlanId(data[value].id.id);
                                    this.setPlanName(data[value].name);
                                  }}>
                                    3D
                                  </Button>
                                </div>
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} className='center'>
                          <div style={{
                            margin: 'auto',
                            width: '80%',
                            padding: '10px'
                          }}>
                            <Button  variant='secondary'
                              onClick={() => {
                                this.setPlanId(data[value].id.id);
                                this.setPlanName(data[value].name);
                                this.props.setSidePanelCallback(true);
                              }}>
                                comments
                            </Button>
                        </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{
                    minHeight: {xs: '50px'},
                    maxHeight: '250px'
                  }}>
                    <div style={{
                      overflowY: 'auto',
                      height: '100%'
                    }}>
                      <h3 className='name'>Notifications</h3>
                      <ListAlarms entityId={data[value].id.id}
                        entityType='ASSET'
                        buttonWasClickedCallback={this.getRC}/>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        );
      } else {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={`rc${value}`}
              onClick={ () => { this.setState({id: data[value].id.id}); }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} style={{color: 'white'}}>
                      <h3 className='name'>
                        {this.renderBellIconButton(
                          this.state.rcBellIcons[data[value].id.id],
                          data[value].name)}
                        {' '}
                        {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                      </h3>
                    </Grid>
                    <Grid item xs={3} style={{color: 'white'}}>
                      {this.dateString(data[value].attributes.date)}
                    </Grid>
                  </Grid>
                </Box>
            </Item>
          </Grid>
        );
      }
    }
    return items;
  }

  /**
   * Renders the bell icon next to reality captures name with active alarms.
   *
   * {boolean} bellIcon - the reality capture has active alarms
   * {stirng} text - reality capture name
   */
  renderBellIconButton = (bellIcon, text) => {
    if (bellIcon) {
      return (
        <>
          {text}
          {' '}
          <NotificationsActiveIcon/>
        </>
      );
    }
    return text;
  }

  // ## NOTIFICATION SERVICE
  /**
   * Specifies which reality capture has active alarms.
   */
  getRcBellIcons = async () => {
    let rcBellIcons = {};
    for (let rc of this.state.data) {
      const bellIcon = await this.getNotifications('ASSET', rc.id.id);
      rcBellIcons[rc.id.id] = bellIcon;
    }
    this.setState({rcBellIcons: rcBellIcons})
  }

  /**
   * Gets specified entity's alarms from ThingsBoard API.
   *
   * @param {string} entityType - entity type
   * @param {string} entityId - entity id
   */
  getNotifications = async (entityType, entityId) => {
    const response = await getAllAlarms(this.props.token, entityType, entityId);
    for (let alarm of response) {
      if (alarm.status === 'ACTIVE_UNACK') {
        return true;
      }
    }
    return false;
  }

  // ## REALITY CAPTURES SERVICE
  /**
   * Gets user reality captures from selected jobsite.
   */
  getRC = async () => {
    this.setState({ loading: true, modal: false });
    const realityCaptures = await
      getRealityCapturesFromJobsiteId(this.props.token, this.props.jobsiteId);
    this.sortRCs(realityCaptures);
  };

  /**
   * Deletes a reality capture.
   *
   * @param {string} id - reality capture id
   */
  deleteRC = async (id) => {
    this.setState({ loading: true });
    const response = await deleteRealityCapture(this.props.token, id);
    this.setState({
      delmsg: response.message,
      loading: false,
    });
    this.props.rcWasDeletedCallback();
  };

  // ## SORTING SERVICE
  /**
   * Selects the appropriate sorting function.
   *
   * @param {[object]} realityCaptures - the list of reality captures
   */
  sortRCs = (realityCaptures) => {
    switch (this.props.sortOption) {
      case 'date':
        this.sortOnDate(realityCaptures);
        break;
      case 'name':
        this.sortOnName(realityCaptures);
        break;
    }
  }

  /**
   * Sorts reality captures on their date.
   *
   * @param {[object]} realityCaptures - the list of realityCaptures
   */
  sortOnDate = async (realityCaptures) => {
    realityCaptures.sort(function(a, b){
      return new Date(a.attributes.date) - new Date(b.attributes.date)
    });
    await this.setState({
      data: realityCaptures,
      loading: false
    });
    this.getRcBellIcons();
  }

  /**
   * Sorts reality captures on their name.
   *
   * @param {[object]} realityCaptures - the list of reality captures
   */
  sortOnName = async (realityCaptures) => {
    realityCaptures.sort(function(a, b){
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    await this.setState({
      data: realityCaptures,
      loading: false
    });
    this.getRcBellIcons();
  }

  // ## HELPER FUNCTIONS
  /**
   * Transforms a date to DD/MM/YYYY format
   *
   * @param {string} date - reality capture date
   * @returns {string} dd - formated reality capture date
   */
  dateString = (date) => {
    const d = new Date(date);
    const dd = d.toLocaleString().substring(0,10);
    return dd;
  }

  // ## REDUX
   /**
   * Saves reality capture id.
   *
   * @param {string} id - reality capture id
   */
  setPlanId = (id) => {
    const action = { type: 'SET_RC_ID', value: id }
    this.props.dispatch(action);
  }

  /**
   * Saves reality capture name.
   *
   * @param {string} name - reality capture name
   */
  setPlanName = (name) => {
    const action = { type: 'SET_RC_NAME', value: name }
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    coordinates, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    coordinates: state.storeCoordinates.coordinates,
    permission: state.storePermission.allow
  }
}

export default connect(mapStateToProps)(ListRealityCaptures);
