/**
 * Calls Thingsboard API to get specified device's alarms.
 *
 * @param {string} token - user JWT token
 * @param {string} entityType - entity type
 * @param {string} id - entity id
 */
export async function getAlarms (token, entityType, id) {
  const url = `${process.env.REACT_APP_TB}/api/alarm/${entityType}/${id}` +
    `?pageSize=30&page=0&status=ACTIVE_UNACK`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse.data;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get alarms',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to get specified device's alarms.
 *
 * @param {string} token - user JWT token
 * @param {string} entityType - entity type
 * @param {string} id - entity id
 */
export async function getAllAlarms (token, entityType, id) {
  const url = `${process.env.REACT_APP_TB}/api/alarm/${entityType}/${id}` +
    `?pageSize=30&page=0`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse.data;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get alarms',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to acknowledge specified alarm.
 *
 * @param {string} token - user JWT token
 * @param {string} id - alarm id
 */
export async function ackAlarm (token, id) {
  const url = `${process.env.REACT_APP_TB}/api/alarm/${id}/ack`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  if (!response.ok) {
    console.log('Error while trying to acknowledge alarm');
  }
  return 'done';
};

/**
 * Calls Thingsboard API to clear specified alarm.
 *
 * @param {string} token - user JWT token
 * @param {string} id - alarm id
 */
export async function clearAlarm (token, id) {
  const url = `${process.env.REACT_APP_TB}/api/alarm/${id}/clear`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  if (!response.ok) {
    console.log('Error while trying to clear alarm');
  }
  return 'done';
};

/**
 * Calls Thingsboard API to unacknowledge specified device's alarms.
 *
 * @param {string} token - user JWT token
 * @param {object} alarm - alarm to unclear
 */
export async function unackAlarm (token, alarm) {
  const url = `${process.env.REACT_APP_TB}/api/alarm`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
    body: JSON.stringify(alarm)
  });
  try {
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to unacknowledge alarm',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to unclear specified device's alarms.
 *
 * @param {string} token - user JWT token
 * @param {object} alarm - alarm to unclear
 */
export async function unclearAlarm (token, alarm) {
  const url = `${process.env.REACT_APP_TB}/api/alarm`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
    body: JSON.stringify(alarm)
  });
  try {
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to unclear alarm',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls Thingsboard API to delete specified device's alarms.
 *
 * @param {string} token - user JWT token
 * @param {string} id - alarm id
 */
export async function deleteAlarm (token, id) {
  const url = `${process.env.REACT_APP_TB}/api/alarm/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'X-Authorization': `bearer ${token}`,
    },
  });
  if (!response.ok) {
    console.log('Error while trying to clear alarm');
  }
  return 'done';
};
