const initialState = { poiType: ''}

function storeActivePoIType(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_POI_TYPE':
      nextState = { poiType:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeActivePoIType;
