// # IMPORTS
import React from 'react';

// # MAIN
/**
 * This class allows the rendering of the 'Contact' page.
 */
export default function Contact() {
  return <h1 style={{'minHeight': 'calc(100vh - 70px)'}}>Contact</h1>;
}
