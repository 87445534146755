// # IMPORTS
import React from 'react';

// # MAIN
/**
 * This class allows the rendering of the 'Sign Up' page.
 */
export default function SignUp() {
  return <h1>Sign Up</h1>;
}
