// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from '@bbri/ui';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';

// ## LOCAL IMPORTS
import DeleteAlarm from './DeleteAlarm';
import { getAllAlarms, ackAlarm, clearAlarm, unackAlarm, unclearAlarm,
  deleteAlarm } from '../../services/thingsboard/alarms';
import store from '../../redux/store';
import './ListAlarms.css';

// ## THEME VARIABLES
const Alarm = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f1ab57',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'black',
}));
const ClearedAlarm = styled(Paper)(({ theme }) => ({
  backgroundColor: 'gray',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
}));
const ClearedUnackedAlarm = styled(Paper)(({ theme }) => ({
  backgroundColor: '#7393B3',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
}));
const AckedAlarm = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'gray'
}));

// # MAIN
/**
 * This class allows the rendering of an alarm list for specified entit.
 *
 * @param {string} entityId - entity id
 * @param {string} entityType - entity type
 * @param {function} buttonWasClickedCallback - callback for when an alarm
 *    status changes
 */
class ListAlarms extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modal14: false,
      details: '',
      modal: false,
      alarmId: ''
    };
  }

  /**
   * Gets alarms on component mount.
   */
  componentDidMount = () => {
    this.getAlarms();
  }

  /**
   * Opens the 'alarm details' modal and generates the modal content.
   *
   * @param {object} alarm - a ThingsBoard entity alarm
   */
  openDetails = (details) => {
    if (Object.keys(details).length === 0) {
      details.data = 'No details';
    }
    this.setState({
      details: details.data,
      modal14: true
    });
  }

  /**
   * Sorts alarms on their status.
   *
   * @param {[object]} alarms - all ThingsBoard entity's alarms
   * @returns {[object]} alarms - sorted alarms
   */
  sortAlarms = (alarms) => {
    alarms.sort((a, b) => {
      if (a.status === 'ACTIVE_UNACK') {
        return -1
      } else if (b.status === 'ACTIVE_UNACK') {
        return 1
      } else if (a.status === 'ACTIVE_ACK') {
        return -1
      } else if (b.status === 'ACTIVE_ACK') {
        return 1
      } else if (a.status === 'CLEARED_UNACK') {
        return -1
      } else if (b.status === 'CLEARED_UNACK') {
        return 1
      } else if (a.status === 'CLEARED_ACK') {
        return -1
      } else if (b.status === 'CLEARED_ACK') {
        return 1
      }
    })
    return alarms;
  }

  // COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return(
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {this.renderAlarms()}
          </Grid>
        </Box>
        <DeleteAlarm isOpen={this.state.modal}
          alarmWasDeletedCallback={this.getAlarms}
          alarmId={this.state.alarmId}/>
        {this.renderDetails()}
      </>
    )
  }

  /**
   * Renders the alarm list.
   */
  renderAlarms = () => {
    const data = this.state.data;
    const items =[];
    for (const [value] of data.entries()) {
      if (data[value].status === 'CLEARED_ACK') {
          items.push(
            <Grid item xs={12} key={value}>
              <ClearedAlarm>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Tooltip title='unacknowledge alarm'>
                      <Checkbox checked={true}
                        onChange={() => {this.unackAlarm(data[value])}}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white',
                          },
                        }}/>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    {data[value].name}
                  </Grid>
                  <Grid item xs={3}>
                    {moment(data[value].createdTime).format("llll")}
                  </Grid>
                  <Grid item xs={3} style={{
                    marginTop: '9px'
                  }}>
                    <Tooltip title='unclear alarm'>
                      <RestartAltIcon className='whiteIcon' onClick={() => {
                        this.unclearAlarm(data[value]);
                      }}/>
                    </Tooltip>
                    <Tooltip title='delete alarm'>
                      <DeleteForeverIcon className='whiteIcon' onClick={() => {
                        this.setState({
                          modal: true,
                          alarmId: data[value].id.id
                        });
                      }}/>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title='show details'>
                      <DescriptionIcon className='whiteIcon'
                        onClick={() => {this.openDetails(data[value].details)}}
                        style={{marginTop: '9px'}}/>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ClearedAlarm>
            </Grid>
          )
      } else if (data[value].status === 'CLEARED_UNACK') {
          items.push(
            <Grid item xs={12} key={value}>
              <ClearedUnackedAlarm>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Tooltip title='acknowledge alarm'>
                      <Checkbox checked={false}
                        onChange={() => {this.ackAlarm(data[value].id.id)}}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white',
                          },
                        }}/>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    {data[value].name}
                  </Grid>
                  <Grid item xs={3}>
                    {moment(data[value].createdTime).format("llll")}
                  </Grid>
                  <Grid item xs={3} style={{
                    marginTop: '9px'
                  }}>
                    <Tooltip title='unclear alarm'>
                      <RestartAltIcon className='whiteIcon' onClick={() => {
                        this.unclearAlarm(data[value]);
                      }}/>
                    </Tooltip>
                    <Tooltip title='delete alarm'>
                      <DeleteForeverIcon className='whiteIcon' onClick={() => {
                        this.setState({
                          modal: true,
                          alarmId: data[value].id.id
                        });
                      }}/>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title='show details'>
                      <DescriptionIcon className='whiteIcon'
                        onClick={() => {this.openDetails(data[value].details)}}
                        style={{marginTop: '9px'}}/>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ClearedUnackedAlarm>
            </Grid>
          )
      } else if (data[value].status === 'ACTIVE_ACK') {
        items.push(
          <Grid item xs={12} key={value}>
            <AckedAlarm>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Tooltip title='unacknowledge alarm'>
                    <Checkbox checked={true}
                      onChange={() => {this.unackAlarm(data[value])}}
                      sx={{
                        color: 'gray',
                        '&.Mui-checked': {
                          color: 'gray',
                        },
                      }}/>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  {data[value].name}
                </Grid>
                <Grid item xs={3}>
                  {moment(data[value].createdTime).format("llll")}
                </Grid>
                <Grid item xs={3} style={{
                  marginTop: '9px'
                }}>
                  <Tooltip title='clear alarm'>
                    <ClearIcon className='blackAckIcon' onClick={() => {
                      this.clearAlarm(data[value].id.id);
                    }}/>
                  </Tooltip>
                  <Tooltip title='delete alarm'>
                    <DeleteForeverIcon className='blackAckIcon' onClick={() => {
                      this.setState({
                        modal: true,
                        alarmId: data[value].id.id
                      });
                    }}/>
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title='show details'>
                    <DescriptionIcon className='blackAckIcon'
                      onClick={() => {this.openDetails(data[value].details)}}
                      style={{marginTop: '9px'}}/>
                  </Tooltip>
                </Grid>
              </Grid>
            </AckedAlarm>
          </Grid>
        )
      } else {
        items.push(
          <Grid item xs={12} key={value}>
            <Alarm>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Tooltip title='acknowledge alarm'>
                    <Checkbox checked={false}
                      onChange={() => {this.ackAlarm(data[value].id.id)}}
                      sx={{
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}/>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  {data[value].name}
                </Grid>
                <Grid item xs={3}>
                  {moment(data[value].createdTime).format("llll")}
                </Grid>
                <Grid item xs={3} style={{
                  marginTop: '9px'
                }}>
                  <Tooltip title='clear alarm'>
                    <ClearIcon className='blackIcon' onClick={() => {
                      this.clearAlarm(data[value].id.id);
                    }}/>
                  </Tooltip>
                  <Tooltip title='delete alarm'>
                    <DeleteForeverIcon className='blackIcon' onClick={() => {
                      this.setState({
                        modal: true,
                        alarmId: data[value].id.id
                      });
                    }}/>
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title='show details'>
                    <DescriptionIcon className='blackIcon'
                      onClick={() => {this.openDetails(data[value].details)}}
                      style={{marginTop: '9px'}}/>
                  </Tooltip>
                </Grid>
              </Grid>
            </Alarm>
          </Grid>
        )
      }
    }
    return items;
  }

  /**
   * Renders the 'alarm details' modal.
   */
  renderDetails = () => {
    return (
      <Dialog open={this.state.modal14} onClose={() => {
        this.setState({modal14: false})
      }}>
        <DialogTitle>Alarm details</DialogTitle>
        <DialogContent>
          {this.state.details}
        </DialogContent>
        <DialogActions>
          <Button variant='secondary'
            onClick={() => {
              this.setState({modal14: false})
            }}>
              Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  // ALARM SERVICE
  /**
   * Gets selected entity alarms.
   */
  getAlarms = async () => {
    const response = await getAllAlarms(this.props.token, this.props.entityType,
      this.props.entityId);
    const sortedAlarms = await this.sortAlarms(response);
    this.setState({
      data: sortedAlarms,
      modal: false
    });
  }

  /**
   * Changes selected alarm status to acknowledged.
   *
   * @param {string} id - alarm id
   */
  ackAlarm = async (id) => {
    const response = await ackAlarm(this.props.token, id);
    this.getAlarms();
    this.props.buttonWasClickedCallback();
  }

  /**
   * Changes selected alarm status to unacknowledged.
   *
   * @param {object} alarm - a ThingsBoard entity alarm
   */
  unackAlarm = async (alarm) => {
    switch (alarm.status) {
      case 'ACTIVE_ACK':
        alarm.status = 'ACTIVE_UNACK';
        break;
      case 'CLEARED_ACK':
        alarm.status = 'CLEARED_UNACK';
        break;
    }
    const response = await unackAlarm(this.props.token, alarm);
    this.getAlarms();
    this.props.buttonWasClickedCallback();
  }

  /**
   * Changes selected alarm status to cleared.
   *
   * @param {string} id - alarm id
   */
  clearAlarm = async (id) => {
    const response = await clearAlarm(this.props.token, id);
    this.getAlarms();
    this.props.buttonWasClickedCallback();
  }

  /**
   * Changes selected alarm status to uncleared.
   *
   * @param {object} alarm - a ThingsBoard entity alarm
   */
  unclearAlarm = async (alarm) => {
    switch (alarm.status) {
      case 'CLEARED_ACK':
        alarm.status = 'ACTIVE_ACK';
        break;
      case 'CLEARED_UNACK':
        alarm.status = 'ACTIVE_UNACK';
        break;
    }
    const response = await unclearAlarm(this.props.token, alarm);
    this.getAlarms();
    this.props.buttonWasClickedCallback();
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
    return {
        id: state.storeUserId.id,
        isLoggedIn: state.isLogged.isLoggedIn,
        token: state.storeToken.token,
        jobsiteName: state.storeActiveJobsiteName.name,
        jobsiteId: state.storeActiveJobsiteId.jobsiteId,
        rcId: state.storeActiveRCId.rcId,
        rcName: state.storeActiveRCName.name,
        poiId: state.storeActivePoIId.poiId,
        permission: state.storePermission.allow,
    }
}

export default connect(mapStateToProps)(ListAlarms);
