/**
 * Calls API to get user's comments associated to specified entity.
 * GET /comments/tbEntity/:tbEntityId/
 *
 * @param {string} token - user's JWT token
 * @returns {[object]} - API response or placeholder message
 */
export const getComments = async (token, tbEntityId) => {
  const url = `${process.env.REACT_APP_HOST}/comments/tbEntity/${tbEntityId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get comments',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

export default getComments;
