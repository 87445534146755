// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';

// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import ListJobsites
  from '../../components/jobsites/list/ListJobsites';
import SortJobsites
  from '../../components/sort/SortJobsites';
import LeafletMap from '../../components/leaflet/LeafletMap';
import { getAttributes } from '../../services/thingsboard/telemetry';
import pinIcon from '../../assets/pin.png';
import store from '../../redux/store';
import './Home.css';

// # MAIN
/**
 * This class allows the rendering of the 'Home' page.
 */
class Home extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      sortOption: 'date',
      coordinates: [],
      jobsiteId: '',
      editMode: false
    };
  }

  /**
   * Sets the previous page name on component unmount.
   */
  componentWillUnmount = () => {
    this.setPreviousPage();
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    let center = [51.505, -0.09];
    return (
      <Box sx={{ flexGrow: 1 }} style={{overflowY: 'scroll', height: '85vh'}}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={2}>
            <Link className='back' component={RouterLink}
              to={`/${this.props.previousPage}`}
              underline='none'>
                <ArrowBackIcon style={{fontSize: '50px'}}/>
            </Link>
          </Grid>
          <Grid item xs={10}>
            <p className='title center'>My jobsites</p>
          </Grid>
          <Grid item xs={12}>
            <div className='center' style={{
              width: '80%',
              position: 'relative'
            }}>
              <LeafletMap coordinates={this.state.coordinates}
                markerWasClickedCallback={this.propagateJobsiteSelection}
                icon={pinIcon} iconAnchor={[30, 70]}
                popupAnchor={[0, -70]} iconSize={[10, 10]}
                entityType={'ASSET'} draggable={this.state.editMode}/>
              <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: '400'
              }}>
                <LockOutlinedIcon className='icon' onClick={() => {
                  this.setState({editMode: true});
                }} hidden={this.state.editMode} style={{
                  fontSize: '50px'
                }}/>
                <LockOpenOutlinedIcon className='icon' onClick={() => {
                  this.setState({editMode: false});
                }} hidden={!this.state.editMode} style={{
                  fontSize: '50px'
                }}/>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className='sortContainer'>
            <div className='sortButton'>
              <SortJobsites
                sortOptionHasChangedCallback={this.sortOptionHasChanged}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            {this.renderjobsites()}
          </Grid>
        </Grid>
      </Box>
    );
  }

  /**
   * Renders the jobsite list.
   */
  renderjobsites = () => {
    return  (
      <>
        <div>
          <ListJobsites sortOption={this.state.sortOption}
            jobsiteListCallback={this.saveCoordinates}
            selectedJobsite={this.state.jobsiteId}
            editMode={this.state.editMode}/>
        </div>
      </>
    )
  }

  // ## COORDINATES SERVICE
  /**
   * Saves each user's jobsite coordinates.
   *
   * {[object]} jobsites - user's jobsites
   */
  saveCoordinates = async (jobsites) => {
    let coordinates = [];
    for (let jobsite of jobsites) {
      const attributes = await getAttributes(this.props.token, jobsite.id.id,
        'ASSET');
      let coordinateObject = {
        key: jobsite.id.id,
        elementName: jobsite.name
      };
      let jobsiteHasLatitude = false;
      let jobsitehasLongitude = false;
      for (let attribute of attributes) {
        if (attribute.key === 'latitude') {
          coordinateObject.latitude = attribute.value;
          jobsiteHasLatitude = true;
        } else if (attribute.key === 'longitude') {
          coordinateObject.longitude = attribute.value;
          jobsitehasLongitude = true;
        }
      }
      if (jobsiteHasLatitude && jobsitehasLongitude) {
        coordinates.push(coordinateObject);
      }
    }
    this.setState({
      coordinates: coordinates
    })
  }

  // ## COMPONENT COMMUNICATION
  /**
   * Saves the selected jobsite as a state variable.
   *
   * @param {string} jobsiteId - active jobsite id
   */
  propagateJobsiteSelection = (jobsiteId) => {
    this.setState({
      jobsiteId: jobsiteId
    });
  }

  /**
   * Saves the selected sort option.
   *
   * @param {string} sortOption - select field value
   */
  sortOptionHasChanged = async (sortOption) => {
    this.setState({ sortOption: sortOption });
  }

  // ## REDUX
  /**
   * Saves the page name as the previous page.
   */
   setPreviousPage = () => {
     const action = { type: 'SET_PREVIOUS_PAGE', value: 'home' }
     this.props.dispatch(action);
   }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId, poiId, permission,
 *    previousPage - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    poiId: state.storeActivePoIId.poiId,
    permission: state.storePermission.allow,
    previousPage: state.storePreviousPage.page
  }
}

export default connect(mapStateToProps)(Home);
