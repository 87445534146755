const initialState = { coordinates: ''}

function storeCoordinates(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_COORDINATES':
      nextState = { coordinates:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeCoordinates;
