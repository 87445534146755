/**
 * Creates a point of interest.
 * POST /point-of-interests/
 *
 * @param {string} token - user JWT token
 * @param {???} name - point of interest name
 * @param {integer} type - point of interest type
 * @param {string} jobsiteId - associated jobsite id
 */
export async function postPointOfInterest (token, name, jobsiteId, type) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify({
      name: name,
      jobsiteId: jobsiteId,
      type: type
    }),
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    const responsemsg = {
      message: 'Point of interest added with success',
      detail: jsonResponse
    };
    return responsemsg;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to create point of interest',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

/**
 * Creates a relation between a point of interest and a jobsite.
 * POST /point-of-interests/:pointOfInterestId/jobsite/:jobsiteId/
 *
 * @param {string} token - user JWT token
 * @param {integer} poiId - point of interest id
 * @param {string} jobsiteId - associated reality capture id
 */
export async function postPointOfInterestJobsiteRelation (token, poiId,
  jobsiteId) {
    const url = `${process.env.REACT_APP_HOST}/point-of-interests` +
      `/${poiId}/jobsite/${jobsiteId}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      }
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      const responsemsg = {
        message: 'Relation added with success',
        detail: jsonResponse
      };
      return responsemsg;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to create relation',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};

/**
 * Creates a relation between a point of interest and a reality capture.
 * POST /point-of-interests/:pointOfInterestId/reality-capture/:realityCaptureId/
 *
 * @param {string} token - user JWT token
 * @param {integer} poiId - point of interest id
 * @param {string} realitycaptureId - associated reality capture id
 */
export async function postPointOfInterestRCRelation (token, poiId,
  realitycaptureId) {
    const url = `${process.env.REACT_APP_HOST}/point-of-interests` +
      `/${poiId}/reality-capture/${realitycaptureId}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      }
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      const responsemsg = {
        message: 'Relation added with success',
        detail: jsonResponse
      };
      return responsemsg;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to create relation',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};

/**
 * Adds a picture to a point of interest.
 * POST /point-of-interests/:pointOfInterestId/picture
 *
 * @param {string} token - user JWT token
 * @param {integer} poiId - point of interest id
 * @param {string} data - picture
 */
export async function postPointOfInterestPicture (token, poiId,
  data) {
    const url = `${process.env.REACT_APP_HOST}/point-of-interests` +
      `/${poiId}/picture`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`
      },
      body: data,
    });
    try {
      const jsonResponse = await response.json();
      if (jsonResponse.status) { throw jsonResponse; }
      const responsemsg = {
        message: 'Picture added with success',
        detail: jsonResponse
      };
      return responsemsg;
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to add picture',
        detail: err
      };
      console.log(errormsg);
      return errormsg;
    }
};
