const initialState = { rcId: ''}

function storeActiveRCId(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_RC_ID':
      nextState = { rcId:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeActiveRCId;
