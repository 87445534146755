// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Panel, Table, Subheader, Button } from '@bbri/ui';

// ## MUI
import CachedIcon from '@mui/icons-material/Cached';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import { getPointsOfInterestFromRCId }
  from '../../../services/api/pointsOfInterest/getPointsOfInterest';
import { getAlarms } from '../../../services/thingsboard/alarms';
import { putPointOfInterestSeverity }
  from '../../../services/api/pointsOfInterest/putPointOfInterest';
import {deletePointOfInterestRelation}
  from '../../../services/api/pointsOfInterest/deletePointOfInterest';
import AddPointOfInterest from '../add/AddPointOfInterest';
import store from '../../../redux/store';
import './ListPointsOfInterest.css';

// # MAIN
/**
 * This class allows the rendering of a list of points of interest.
 *
 * @param {[object]} freePoints - user generated Potree point measurements
 * @param {function} getPointCoordinatesCallback - callback for when the dialog
 *    opens. Allows the component to get user generated Potree point
 *    measurements
 * @param {function} setSidePanelCallback - callback for when the comments side
 *    panel is opened
 * @param {function} generateAnnotationCallback - callback for when a point of
 *    interest was created and an annotation should be created for it
 * @param {function} setTelemetrySidePanelCallback - callback for when the
 *    telemetry side panel is opened
 * @param {function} setCameraPositionCallback - callback for when the Potree
 *    camera should be zoomed on a device position
 */
class ListPointsOfInterest extends React.Component{
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      message: '',
      collapseID: '',
      alarms:[],
      delmsg:'',
      modal: false,
      id: '',
      devices:[],
      nomDevices:[]
    };
  }

  /**
   * Loads the points of interest on component mount.
   */
  componentDidMount = () => {
    this._isMounted = true;
    this.getPointsOfInterest();
  }


  /**
   * Get the list of alarms for the active point of interest.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.poiId !== prevProps.poiId){
      this.getAlarms(this.props.poiId);
    }
  }

  /**
   * Unsets the '_isMounted' variable on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  /**
   * Toggles the point of interest deletion warning popup.
   */
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return(
      <>
        <Subheader>
          POINTS OF INTEREST
        </Subheader>
        <CachedIcon className='iSyncp'
          style={{width: '0.8em', height: '0.8em'}}
          onClick={()=>{
            this.getPointsOfInterest();
        }}/>
        {this.loadAjoutpoint()}
        <div className='deviceTableContainer'>
          <Panel noPadding>
            <Panel.Body>
              <Table  className='tr'>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>Severity</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.loading()}
                </tbody>
              </Table>
            </Panel.Body>
          </Panel>
        </div>
        <Dialog open={this.state.modal}>
          <DialogContent>
            <p className='warningContainer'>
              <WarningIcon className='warning'/>
            </p>
            <span>
              Are you sure you want to delete this point of interest ?
            </span>
            {loadingRender(this.state.loading, this.state.delmsg)}
          </DialogContent>
          <DialogActions>
            <Button variant='secondary'
              onClick={() => {
                this.toggle();
                this.setState({delmsg: ''})
              }}>
                NO
            </Button>
            <Button variant='primary'
              onClick={() => {
                this.deletePointOfInterestRelation(this.state.id);
                this.toggle();
                this.setState({delmsg: ''});
              }}>
                YES
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  /**
   * Renders the 'add comment' popup.
   *
   * @param {string} idp - point of interest id
   * @returns {JSX} - 'add comment' popup
   */
  loadAjoutCom = (idp) => {
    return (
      <div style={{
        margin: '0 50px 0 0'
      }}>
        <Button onClick={() => {this.props.setSidePanelCallback(true)}}>
          comments
        </Button>
      </div>
    )
  }

  /**
   * Renders trash icon next to point of interest.
   *
   * @param {string} id - point of interest id
   * @param {string} message - ???
   * @returns {JSX} - trash icon
   */
  loadTrash = (id, message) => {
    if(this.props.permission === true){
      return(
        <DeleteForeverIcon className='trash'
          style={{width: '0.8em', height: '0.8em'}}
          onClick={() => {
            this.toggle();
            this.setState({ id: id });
            this.setState({ delmsg: message });
          }}/>
      )
    }
  }

  /**
   * Generates a list of points of interest associated with active
   *    reality capture
   *
   * @returns {[JSX]} items - list of points of interest
   */
  listPoints = () => {
    const data = this.state.data;
    let items =[];
    let result;
    for (const [value] of data.entries()) {
      for (let rcId of Object.keys(data[value].attributes.coordinates)) {
        if (rcId === this.props.rcId) {
          let coordinatesArray =
            data[value].attributes.coordinates[rcId].split(',');
          let coordinates = {
            x: coordinatesArray[0],
            y: coordinatesArray[1],
            z: coordinatesArray[2]
          }
          this.props.generateAnnotationCallback(coordinates, data[value]);
        }
      }
      items.push(
        <tr className='tr'
          key={value}
          onClick={() => {
            this.setPointId(data[value].id.id);
            this.setPointName(data[value].name)
            this.setCoordinates(
              data[value].attributes.coordinates[this.props.rcId]
            );
            this.getAlarms(data[value].id.id);
            this.props.setCameraPositionCallback(
              data[value].attributes.coordinates[this.props.rcId]
            );
          }}>
            <td>{data[value].name}</td>
            <td>
              {data[value].attributes.severity}
            </td>
            <td>{this.loadOpenTelemetrySidePanel(data[value].name,
              data[value].id.id, data[value].type)}</td>
            <td>{this.loadAjoutCom(data[value].id.id)}</td>
            <td>{this.loadTrash(data[value].id.id, '')}</td>
        </tr>
      );
    }
    return items;
  }

  /**
   * Renders the telemetry button for opening the telemetry side panel.
   *
   * @param {string} name - device name
   * @param {string} id - device id
   * @param {string} type - device type
   */
  loadOpenTelemetrySidePanel = (name, id, type) => {
    return (
      <div style={{
        margin: '0 50px 0 0'
      }}>
        <Button  variant='secondary' onClick={() => {
          this.props.setTelemetrySidePanelCallback(true, name, id);
          this.setDeviceType(type);
        }}>
          telemetry
        </Button>
      </div>
    )
  }

  /**
   * Renders a list of points of interest or a message if none was found.
   *
   * @returns {[JSX]} - list of points of interest or message
   */
  loading = () => {
    if(this.state.data.length === 0){
      return (
        <tr>
          <td/>
          <td>No point in database... Please insert one to start</td>
          <td/>
        </tr>
      )
    } else{
      return (this.listPoints())
    }
  }

  /**
   * Renders the 'add point of interest' popup.
   *
   * @returns {JSX} - 'add  point of interest' popup
   */
  loadAjoutpoint = () => {
    if(this.props.permission === true){
      return(
        <>
        <AddPointOfInterest freePoints={this.props.freePoints}
          getPointCoordinatesCallback={this.props.getPointCoordinatesCallback}
          pointOfInterestWasCreatedCallback={this.getPointsOfInterest}/>
        </>
      )
    }
  }

  // ## ALARM SERVICE
  /**
   * Calls Thingsboard API to get specified device's alarms.
   *
   * @param {string} id - device id
   */
  getAlarms = async (id) => {
    this.setState({loading: true});
    const alarms = await getAlarms(this.props.token, 'DEVICE', id);
    if (this._isMounted) {
      this.setState({
        alarms: alarms
      });
    }
    this.putSeverity();
    this.setState({
      loading: false
    });
  };

  // ## POINTS OF INTEREST SERVICE
  /**
   * Calls API to get user points of interest associated with specified reality
   *    capture.
   */
  getPointsOfInterest = async () => {
    this.setState({loading: true});
    const pointsOfInterest = await getPointsOfInterestFromRCId(this.props.token,
      this.props.rcId);
    if (this._isMounted) {
      this.setState({
        data: pointsOfInterest
      });
    }
    this.setState({
      loading: false,
    });
  };

  /**
   * Calls API to delete point of interests.
   *
   * @param {string} id - point of interest id
   */
  deletePointOfInterestRelation = async (id) => {
    this.setState({loading: true});
    const response = await deletePointOfInterestRelation(this.props.token, id,
      this.props.rcId);
    this.setState({
      loading: false,
      delmsg: response.message
    });
    this.getPointsOfInterest();
  };

  // ## SEVERITY SERVICE
  /**
   * Calls API to edit point of interest severity
   */
  putSeverity = async () => {
    this.setState({loading: true});
    const response = await putPointOfInterestSeverity(this.props.token,
      this.props.poiId, this.calculSeverity());
    if (this._isMounted) {
      this.setState({
        putmsg: response.message,
        loading: false
      });
    }
  };

  /**
   * Gives an integer severity value to a string.
   *
   * @param {string} sev - point of interest severity ???
   * @returns {integer} - integer severity value
   */
  sevToInt = (sev) => {
    if(sev === 'CRITICAL'){
      return 8;
    } else if(sev === 'MAJOR'){
      return 5;
    } else if(sev === 'MINOR'){
      return 3;
    } else if(sev === 'WARNING'){
      return 2;
    } else if(sev === 'INDETERMINATE'){
      return 1;
    } else { return 0 }
  }

  /**
   * Calculates the mean severity of the active point of interest.
   *
   * @returns {???} ???
   */
  calculSeverity = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let tabSev = [];
    let tabInt = [];
    let alarms = this.state.alarms;
    if(alarms.length !== 0) {
      alarms.forEach(e => tabSev.push(e.severity));
      tabSev.forEach(e => tabInt.push(this.sevToInt(e)));
      return tabInt.reduce(reducer);
    }
    else {
      tabInt.push(0);
      return tabInt.reduce(reducer);
    }
  }

  // ## REDUX
  /**
   * Saves point of interest id.
   *
   * @param {string} id - point of interest id
   */
  setPointId = (id) => {
    const action = { type: 'SET_POI_ID', value: id }
    this.props.dispatch(action);
  }

  /**
   * Saves point of interest name.
   *
   * @param {string} name - point of interest name
   */
  setPointName = (name) => {
    const action = { type: 'SET_POI_NAME', value: name }
    this.props.dispatch(action);
  }

  /**
   * Saves point of interest coordinates.
   *
   * @param {string} id - point of interest coordinates
   */
  setCoordinates = (coord) => {
    const action = { type: 'SET_COORDINATES_FIXED', value: coord }
    this.props.dispatch(action);
  }

  /**
   * Saves point of interest type.
   *
   * @param {string} type - point of interest type
   */
  setDeviceType = (type) => {
    const action = { type: 'SET_POI_TYPE', value: type }
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
    return {
        id: state.storeUserId.id,
        isLoggedIn: state.isLogged.isLoggedIn,
        token: state.storeToken.token,
        jobsiteName: state.storeActiveJobsiteName.name,
        jobsiteId: state.storeActiveJobsiteId.jobsiteId,
        rcId: state.storeActiveRCId.rcId,
        rcName: state.storeActiveRCName.name,
        poiId: state.storeActivePoIId.poiId,
        permission: state.storePermission.allow,
    }
}

export default connect(mapStateToProps)(ListPointsOfInterest);
