// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Surface, Symbols , ReferenceArea, Brush} from 'recharts';
import _ from "lodash";
import moment from 'moment';
import { Button } from '@bbri/ui';

// ## LOCAL IMPORTS
import {getTelemetry} from '../../../services/thingsboard/telemetry';
import store from '../../../redux/store';
import './AreaChart.css';

// # MAIN
/**
 * This class allows the rendering of an area chart for device's telemetry data.
 *
 * @param {integer} startTs - start timestamp for telemetry data fetch from
 *    ThingsBoard API
 * @param {integer} endTs - end timestamp for telemetry data fetch from
 *    ThingsBoard API
 * @param {[string]} lineDataKeys - array of data keys
 * @param {integer} limit - maximum amount of telemetry data to fetch from
 *    ThingsBoard API
 */
class AreaRechart extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      initialData: [],
      data: [],
      disabled: [],
      chartColors: {},
      left: "dataMin",
      right: "dataMax",
      refAreaLeft: "",
      refAreaRight: "",
      top: "dataMax",
      bottom: "dataMin",
      animation: true
    };
  }

  /**
   * Gets selected device's telemetry on component mount.
   */
  componentDidMount = () => {
    this.getTelemetry();
  }

  /**
   * Gets selected device's telemetry on component update.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.lineDataKeys !== prevProps.lineDataKeys ||
      this.props.colors !== prevProps.colors ||
      this.props.startTs !== prevProps.startTs){
        this.getTelemetry();
    }
  }

  /**
   * Disables data display for clicked key in the chart legend.
   *
   * @param {string} dataKey - chart legend key
   */
  handleClick = dataKey => {
    if (_.includes(this.state.disabled, dataKey)) {
      this.setState({
        disabled: this.state.disabled.filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ disabled: this.state.disabled.concat(dataKey) });
    }
  };

  /**
   * Sets appropriate area colors for each data key.
   */
  generateChartColorsArray = () => {
    let chartColors = {};
    for (let i in this.props.lineDataKeys) {
      chartColors[this.props.lineDataKeys[i]] = this.props.colors[i];
    }
    this.setState({ chartColors: chartColors});
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
	  const {
      refAreaLeft,
      refAreaRight,
		} = this.state;
    if (this.state.data.length === 0) {
      return (<h1 className='center'>No data available</h1>)
    } else {
      return (
        <>
          <div className="highlight-bar-charts" style={{
            userSelect: "none",
            width: '100%',
            height: '100%'
          }}>
            <ResponsiveContainer
      			  width="100%"
      			  height="80%">
                <AreaChart
                  data={this.state.data}
                  margin={{ top: 5, right: 30, left: 0, bottom: 5,}}
                >
                <CartesianGrid strokeDasharray="5 5"/>
                <XAxis
          				dataKey='ts'
          				type="number"
          				domain={['auto', 'auto']}
          				scale = 'linear'
                  tickFormatter={ts => moment(ts).format('MMMM Do YYYY, h:mm a')}
        				/>
                <YAxis/>
                <Tooltip labelFormatter={
                  ts => moment(ts).format('MMMM Do YYYY, h:mm a')
                }/>
                {_.toPairs(this.state.chartColors)
                .filter(pair => !_.includes(this.state.disabled, pair[0]))
                .map(pair => (
                  <Area key={pair[0]} type="monotone" dataKey={pair[0]}
                    stroke={pair[1]} fill={pair[1]} connectNulls={true}/>
                ))}
          			<Brush
          				dataKey='ts'
          				tickFormatter={ts => moment(ts).format('MMMM Do YYYY, h:mm a')}
          			/>
              </AreaChart>
            </ResponsiveContainer>
            <div>
              {this.renderCusomizedLegend()}
            </div>
          </div>
        </>
      )
    }
  };

  /**
   * Dynamically renders a customised and clickable legend, depending on the
   *    data fetched from ThingsBoard.
   */
  renderCusomizedLegend = () => {
    let payload = _.toPairs(this.state.chartColors).map(pair => ({
      dataKey: pair[0],
      color: pair[1]
    }))
    return (
      <div className="customized-legend center">
        {payload.map(entry => {
          const { dataKey, color } = entry;
          const active = _.includes(this.state.disabled, dataKey);
          const style = {
            marginRight: 10,
            color: active ? "#AAA" : "#000"
          };

          return (
            <span key={dataKey}
              className="legend-item"
              onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  };

  // ## TELEMETRY SERVICE
  /**
   * Gets telemetry data from ThingsBoard API.
   */
  getTelemetry = async () => {
    const response = await getTelemetry(this.props.token, this.props.poiId,
      this.props.lineDataKeys.join(','), this.props.startTs, this.props.endTs,
      this.props.limit);
    this.generateChartColorsArray();
    this.generateData(response);
  }

  /**
   * Formats ThingsBoard data to be readable by Recharts.
   *
   * @param {object} response - ThingsBoard API response
   */
  generateData = (response) => {
    let data = [];
    let chartColors = {};
    for (let key of Object.keys(response)) {
      for (let keyData of response[key]) {
        let found = false;
        for (let i in data) {
          if (data[i].ts === keyData.ts) {
            data[i][key] = parseFloat(keyData.value);
            found = true;
            break;
          }
        }
        let date;
        let object = {};
        if (!found) {
          object.ts = keyData.ts;
          object[key] = parseFloat(keyData.value);
          data.push(object);
        }
      }
    }
    data.sort(function (a, b) { return a.ts - b.ts; });
    this.setState({
      initialData: data,
      data: data
    });
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    coordinates, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    coordinates: state.storeCoordinates.coordinates,
    permission: state.storePermission.allow
  }
}

export default connect(mapStateToProps)(AreaRechart);
