// # IMPORTS
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// ## LOCAL IMPORTS
import NavBar from '../components/navbar/NavBar';
import AuthRoutes from './AuthRoutes';
import Welcome from '../pages/welcome/Welcome';
import SignUp from '../pages/signup/SignUp';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import Home from '../pages/home/Home';
import Dashboard from '../pages/dashboard/Dashboard';
import Details from '../pages/details/Details';
import Devices from '../pages/devices/Devices';
import Telemetry from '../pages/telemetry/Telemetry';
import store from '../redux/store';

// # MAIN
export class Routes extends React.Component {
  render = () => {
    return (
      <div >
        <NavBar isLoggedIn={store.getState().isLogged.isLoggedIn}/>
        <Switch>
          <AuthRoutes exact path='/welcome' component={Welcome} type='guest' />
          <AuthRoutes exact path='/'>
            <Redirect to='/welcome' />
          </AuthRoutes>
          <AuthRoutes exact path='/signup' component={SignUp}
            type='guest'/>
          <AuthRoutes exact path='/about' component={About} type='both'/>
          <AuthRoutes exact path='/contact' component={Contact} type='both'/>
          <AuthRoutes exact path='/home' component={Home} type='private'/>
          <AuthRoutes exact path='/dashboard' component={Dashboard} type='private'/>
          <AuthRoutes exact path='/devices' component={Devices} type='private'/>
          <AuthRoutes exact path='/telemetry' component={Telemetry} type='private'/>
          <AuthRoutes exact path='/details' component={Details} type='private'/>
        </Switch>
      </div>
    );
  }
};
