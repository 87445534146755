// # IMPORTS
import React from 'react';

// # MAIN
/**
 * Renders a loading icon if the app is loading.
 * Otherwise, renders the jobsite list.
 *
 * @param {boolean} loading - loading state
 * @param {function} callback - function to call after loading is finished
 * @returns {JSX} - loading message or callback function
 */
const loadingRender = (loading, callback) => {
  if (loading) {
    return (
      <div className='spinner-border' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    )
  } else { return <> {callback} </> }
}

export default loadingRender;
