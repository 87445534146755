const initialState = { token: '' }

function storeToken(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_TOKEN':
      nextState = { token:  action.value }
      return nextState || state
    default:
      return state
  }
}

export default storeToken;
