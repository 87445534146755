/**
 * Calls API to delete a comment.
 * DELETE /comments/:commentId/
 *
 * @param {string} id - comment id
 * @returns {object} - response message
 */
const deleteComment = async (token, id) => {
  const url = `${process.env.REACT_APP_HOST}/comments/${id}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete comment',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

export default deleteComment;
