// # IMPORTS
import React, { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L, { latLngBounds } from 'leaflet';

// ## MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// ## LOCAL IMPORTS
import { saveEntityCoordinates } from '../../services/thingsboard/telemetry';
import store from '../../redux/store';

// # MAIN

// ## RENDERING
/**
 * Renders an Open Street Map through Leaflet.
 *
 * @param {[object]} coordinates - entities coordinates
 * @param icon - icon image
 * @param {[integer]} iconSize - marker icon dimensions
 * @param {[integer]} iconAnchor - marker icon position offset
 * @param {[integer]} popupAnchor - popup position offset
 * @param {string} entityType - entities type
 * @param {boolean} draggable - marker are draggable
 * @param {function} markerWasClickedCallback - callback, when the 'open details'
 *    button is clicked
 */
export default function LeafletMap (props) {
  let centerCoordinates = [50.68965774733732, 4.525268872268888];
  if (props.coordinates[0]) {
    centerCoordinates =
      [props.coordinates[0].latitude, props.coordinates[0].longitude];
  }
  return(
    <MapContainer center={[50.68965774733732, 4.525268872268888]} zoom={13} scrollWheelZoom={true} id='map'>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.coordinates.map((coordinates, index) =>
        <DraggableMarker coordinates={coordinates} icon={props.icon}
          iconSize={props.iconSize} iconAnchor={props.iconAnchor}
          popupAnchor={props.popupAnchor}
          markerWasClickedCallback={props.markerWasClickedCallback}
          entityType={props.entityType}
          key={coordinates.key}
          draggable={props.draggable}/>
      )}
      <SetMapCenter centerCoordinates={centerCoordinates}
        coordinates={props.coordinates}/>
    </MapContainer>
  )
}

// ## MARKER RENDERING & SERVICE
/**
 * Renders draggable markers.
 *
 * @param {[object]} coordinates - entities coordinates
 * @param icon - icon image
 * @param {[integer]} iconSize - marker icon dimensions
 * @param {[integer]} iconAnchor - marker icon position offset
 * @param {[integer]} popupAnchor - popup position offset
 * @param {string} entityType - entities type
 * @param {boolean} draggable - marker are draggable
 * @param {function} markerWasClickedCallback - callback, when the 'open details'
 *    button is clicked
 */
function DraggableMarker (props) {
  const [position, setPosition] = useState([
    parseFloat(props.coordinates.latitude),
    parseFloat(props.coordinates.longitude)])
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          saveCoordinates(marker.getLatLng(), props.coordinates.key, props.entityType);
        }
      }
    }),
    [],
  );
  const cb = () => {
   props.markerWasClickedCallback(props.coordinates.key);
  };
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return (
    <Marker
      position={position}
      eventHandlers={eventHandlers}
      icon={GetIcon(props.icon, props.iconSize, props.iconAnchor,
        props.popupAnchor)}
      draggable={props.draggable}
      ref={markerRef}>
        <Popup>
          <h1>
            {props.coordinates.elementName}
          </h1>
          <Box
            sx={{
              typography: 'body1',
              '& > :not(style) + :not(style)': {
                ml: 2,
              },
            }}
            onClick={preventDefault}
          >
            <Link href="#" onClick={cb}>
              Open details
            </Link>
          </Box>
        </Popup>
    </Marker>
  )
}

/**
 * Saves new entity coordinates after dragging a marker.
 *
 * @param {object} coordinates - entity coordinates
 * @param {string} entityId - entity idea
 * @param {string} entityType - entityType
 */
async function saveCoordinates(coordinates, entityId, entityType) {
  const attributes = {
    latitude: coordinates.lat,
    longitude: coordinates.lng
  };
  await saveEntityCoordinates(
    store.getState().storeToken.token, entityId, entityType, attributes);
}

/**
 * Generates marker icon.
 *
 * @param icon - icon image
 * @param {[integer]} iconSize - marker icon dimensions
 * @param {[integer]} iconAnchor - marker icon position offset
 * @param {[integer]} popupAnchor - popup position offset
 */
function GetIcon(icon, iconSize, iconAnchor, popupAnchor) {
  return L.icon({
    iconUrl: icon,
    _iconSize: iconSize,
    iconAnchor: iconAnchor,
    popupAnchor: popupAnchor
  })
}

// ## LOCATION DISPLAY SERVICE
/**
 * Dynamically sets the map center to display every marker.
 *
 * @param {[float]} centerCoordinates - map's center coordinates
 * @param {[object]} coordinates - entities coordinates
 */
function SetMapCenter (props) {
  const map = useMap();
  map.setView(props.centerCoordinates);
  let markerBounds = latLngBounds([]);
  if (props.coordinates.length && props.coordinates.length > 0){
    props.coordinates.forEach(marker => {
      markerBounds.extend([marker.latitude, marker.longitude]);
    })
    map.fitBounds(markerBounds);
  }
  return null;
}
