// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## LOCAL IMPORTS
import { getPointsOfInterestFromJobsiteId }
  from '../../../services/api/pointsOfInterest/getPointsOfInterest';
import { deletePointOfInterestPictureUrlAttribute }
  from '../../../services/api/pointsOfInterest/deletePointOfInterest';

// ## MUI
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import Input from '@mui/material/Input';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import CancelIcon from '@mui/icons-material/Cancel';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import { postPointOfInterestPicture }
  from '../../../services/api/pointsOfInterest/postPointOfInterest';
import store from '../../../redux/store';
import './AddPicture.css';

// # MAIN
/**
 * This class allows the rendering of an 'add picture' dialog for devices.
 *
 * @param {boolean} isOpen - the 'add picture' dialog is open
 * @param {string} poiId - device id
 * @param {function} setIsOpenCallback - callback for when the dialog is opened
 */
class AddPicture extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      selectedDate: new Date(),
      fileObj: null,
      fileExt: '',
      name : '',
      message: '',
      loading: false,
      today : new Date().toISOString().substring(0,10),
      devices: []
    }
  }

  /**
   * Refreshes the pictures list when the modal is opened.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.isOpen !== prevProps.isOpen){
      this.getDevices();
    }
  }

  /**
   * Toggles the specified modal.
   *
   * @params {integer} nr - the modal number
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <Dialog open={this.props.isOpen} onClose={() => {
          this.setState({message: ''});
          this.props.setIsOpenCallback(false);
        }}>
          <DialogTitle>Add picture</DialogTitle>
          <DialogContent>
            <form onSubmit={preventDefault}>
              <Stack spacing={3}>
                <FormControl variant='standard' fullWidth>
                  <Input className='formitems'
                    required
                    id='addPictureFileInput'
                    onChange={this.handleFile}
                    type='file'
                    variant='standard'
                  />
                </FormControl>
                <div className='msg'>
                  {loadingRender(this.state.loading, this.state.message)}
                </div>
              </Stack>
            </form>
            <Grid container spacing={2}>
              {this.generatePictures()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='secondary' children='Close'
              onClick={() => {
                this.setState({message: ''});
                this.props.setIsOpenCallback(false);
              }}>
            </Button>
            <Button variant='primary' children='Save'
              onClick={this.submitHandler}>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  /**
   * Renders the picture list for selected device.
   */
  generatePictures = () => {
    const items = [];
    for (let poi of this.state.devices) {
      if (poi.id.id === this.props.poiId && poi.attributes.pictureUrl) {
        for (let url of poi.attributes.pictureUrl) {
          items.push(
            <Grid item xs={4} key={url}>
              <img className='pictures' src={url} onClick={() => {
                window.open(url, '_blank');
              }}></img>
              <CancelIcon className='deleteIcons' onClick={() => {
                this.deletePictureUrl(url);
              }}/>
            </Grid>
          )
        }
        return items;
      }
    }
  }

  // ## DEVICE SERVICE
  /**
   * Calls API to delete specified picture.
   *
   * @param {string} pictureUrl - API url to get specific device picture
   */
  deletePictureUrl = async (pictureUrl) => {
    const response = await deletePointOfInterestPictureUrlAttribute(
      this.props.token, this.props.poiId, pictureUrl);
    this.setState({message: response.message});
    this.getDevices();
  }

  /**
   * Gets user devices from selected jobsite.
   */
  getDevices = async () => {
    const devices = await
      getPointsOfInterestFromJobsiteId(this.props.token, this.props.jobsiteId);
    this.setState({devices: devices});
  };

  /**
   * Creates a reality capture.
   *
   * @param data - form data
   */
  postPicture = async (data) => {
    this.setState({ loading: true });
    const response = await postPointOfInterestPicture(this.props.token,
      this.props.poiId, data);
    if (response.message) {
      this.setState({
        message: response.message,
        loading: false,
      });
      this.getDevices();
    } else {
      this.setState({
        loading: false,
        modal14: false
      });
    }
  };

  // ## FORM SERVICE
  /**
   * Calls the postPlan function.
   *
   * @param event - form submission
   */
  submitHandler = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append('picture', this.state.fileObj, this.state.fileObj.name);
    this.postPicture(data);
  }

  /**
   * Saves reality capture zip files.
   *
   * @param event - change of file field value
   */
  handleFile = (event) => {
    this.setState({fileObj : event.target.files[0]})
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
  }
}

export default connect(mapStateToProps)(AddPicture);
