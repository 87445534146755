/**
 * Calls API to delete point of interests.
 * DELETE /jobsites/:jobsiteId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 */
const deleteJobsite = async (token, id) => {
  const url = `${process.env.REACT_APP_HOST}/jobsites/${id}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete the jobsite',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

export default deleteJobsite;
