// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend,
  BarChart, Bar } from "recharts";

// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import AreaRechart from '../../components/telemetry/charts/AreaChart';
import ConcreteMaturity
  from '../../components/telemetry/charts/CONCRETE_MATURITY/ConcreteMaturity';
import SelectTime
  from '../../components/telemetry/time/SelectTime';
import {getTelemetryTimeseriesKeys, getLatestTelemetry}
  from '../../services/thingsboard/telemetry';
import store from '../../redux/store';
import './Telemetry.css';

// # MAIN
/**
 * This class allows the rendering of the 'Telemetry' page.
 */
class Telemetry extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      startTs: new Date().getTime() - (24 * 60 * 60 * 1000),
      endTs: new Date().getTime(),
      lineDataKeys: [],
      colorList: ['#800000', '#42d4f4', '#bfef45', '#e6194B', '#f032e6'],
      colors: [],
      timePeriod: 'last24',
      limit: 100
    };
  }

  /**
   * Generates data line keys and colors for selected device on component mount.
   */
  componentDidMount = () => {
    this.generateLineDataKeysAndColors();
    this.generateEndTs();
  }

  /**
   * Sets the previous page name on component unmount.
   */
  componentWillUnmount = () => {
    this.setPreviousPage();
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <>
        <Box sx={{ flexGrow: 1 }} style={{overflowY: 'scroll', height: '85vh'}}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={2}>
              <Link className='back' component={RouterLink}
                to={`/${this.props.previousPage}`}
                underline='none'>
                  <ArrowBackIcon style={{fontSize: '50px'}}/>
              </Link>
            </Grid>
            <Grid item xs={10}>
              <p className='title center'>{this.renderDeviceName()}</p>
            </Grid>
            <Grid item xs={12} className='timePeriodContainer' style={{
              marginBottom: '50px'
            }}>
              <div className='timePeriodButton'>
                <SelectTime timePeriod={this.state.timePeriod}
                  limit={this.state.limit}
                  timePeriodHasChangedCallback={this.timePeriodHasChanged}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '250px'
              }}>
                <div style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
				  height : '50vh',
                  padding: '0 50px 10px 50px',
                }}>
                  {this.renderTelemetryDisplay()}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  /**
   * Selects the appropriate area chart rendering component based on the
   *    selected device type.
   */
  renderTelemetryDisplay = () => {
    if (this.props.deviceType === 'CONCRETE_MATURITY') {
      return (
        <ConcreteMaturity startTs={this.state.startTs}
          endTs={this.state.endTs} colors={this.state.colors}
          lineDataKeys={this.state.lineDataKeys}
          limit={this.state.limit}/>
      )
    } else {
      return (
        <AreaRechart startTs={this.state.startTs}
          endTs={this.state.endTs} colors={this.state.colors}
          lineDataKeys={this.state.lineDataKeys}
          limit={this.state.limit}/>
      )
    }
  }

  /**
   * Renders the jobsite name.
   */
  renderDeviceName = () => {
    if (this.props.poiName) {
      return `Telemetry history of '${this.props.poiName}' device`;
    } else {
      return '';
    }
  }

  // ## TIMESTAMPS SERVICE
  /**
   * Generates the ThingsBoard API call's endTs field based on the latest
   *    telemetry timestamp from the selected device.
   */
  generateEndTs = async () => {
    const latestTelemetry = await getLatestTelemetry(this.props.token,
      this.props.poiId);
    let endTs = this.state.endTs;
    for (let key of Object.keys(latestTelemetry)) {
      if (latestTelemetry[key][0].ts > endTs) {
        endTs = latestTelemetry[key][0].ts;
      }
    }
    this.setState({endTs: endTs})
  }

  // ## COMPONENT COMMUNICATION
  /**
   * Updates the 'startTs' and 'endTs' field values, used within the ThingsBoard
   *    API call needed to fetch telemetry data, accoring to the user selection.
   *
   * @param {string} timePeriod - string representing the amount of time for
   *    which to fetch telemetry data
   * @param {integer} limit - maximum amount of telemetry data to fetch from
   *    ThingsBoard
   */
  timePeriodHasChanged = (timePeriod, limit) => {
    let startTs;
    switch (timePeriod) {
      case 'last1':
        startTs = new Date().getTime() - (60 * 60 * 1000);
        break;
      case 'last24':
        startTs = new Date().getTime() - (24 * 60 * 60 * 1000);
        break;
      case 'lastWeek':
        startTs = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        break;
      case 'lastMonth':
        startTs = new Date().getTime() - (30 * 7 * 24 * 60 * 60 * 1000);
        break;
      case 'lastYear':
        startTs = new Date().getTime() - (365 * 30 * 7 * 24 * 60 * 60 * 1000);
        break;
    }
    this.setState({
      startTs: startTs,
      limit: Number(limit)
    });
  }

  // ## TELEMETRY SERVICE
  /**
   * Generates the telemetry data area keys and colors according to the
   *    available telemetry data from ThingsBoard.
   */
  generateLineDataKeysAndColors = async () => {
    let response = await getTelemetryTimeseriesKeys(this.props.token,
      this.props.poiId);
    const colors = [];
    if (this.props.deviceType === 'CONCRETE_MATURITY') {
      response = ['temperature', 'fc'];
      this.setState({
        timePeriod: 'lastMonth',
        limit: 1000,
        startTs: new Date().getTime() - (30 * 7 * 24 * 60 * 60 * 1000)
      })
    }
    for (let i in response) {
      const colorIndex = i % this.state.colorList.length;
      colors.push(this.state.colorList[colorIndex])
    }
    this.setState({
      lineDataKeys: response,
      colors: colors
    });
  }

  // ## REDUX
  /**
   * Saves the page name as the previous page.
   */
   setPreviousPage = () => {
     const action = { type: 'SET_PREVIOUS_PAGE', value: 'telemetry' }
     this.props.dispatch(action);
   }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId, rcName, poiId,
 *    poiName, deviceType, permission, previousPage - redux states
 *    mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    poiName: state.storeActivePoIName.poiName,
    deviceType: state.storeActivePoIType.poiType,
    permission: state.storePermission.allow,
    previousPage: state.storePreviousPage.page
  }
}

export default connect(mapStateToProps)(Telemetry);
