// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';
import moment from 'moment';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import UmbrellaIcon from '@mui/icons-material/Umbrella';

// ## LOCAL IMPORTS
import AddJobsite from '../add/AddJobsite';
import DeleteJobsite from '../delete/DeleteJobsite';
import ListAlarms from '../../alarms/ListAlarms';
import getJobsites
  from '../../../services/api/jobsites/getJobsites';
import { findRelationByFrom } from '../../../services/thingsboard/relations';
import { getAllAlarms } from '../../../services/thingsboard/alarms';
import { getAttributes } from '../../../services/thingsboard/telemetry';
import { getWeatherReport } from '../../../services/misc/openWeatherMap';
import store from '../../../redux/store';
import './ListJobsites.css';

// # THEME VARIABLES
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid rgba(0, 135,183, 1)',
  width: '80%'
}));
const WeatherWidget = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid black',
  height: '316px'
}));

// # MAIN
/**
 * This class allows the rendering of the jobsites list.
 *
 * @param {string} sortOption - option on which jobsites are sorted
 * @param {function} jobsiteListCallback - callback for when jobsites are
 *    fetched from ThingsBoard
 * @param {string} selectedJobsite - active jobsite id
 * @param {boolean} editMode - Leaflet Map Component is in edit mode
 */
class ListJobsites extends React.Component{
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      modal: false,
      id: '',
      delmsg:'',
      relations: [],
      devicesBellIcons: {},
      rcBellIcons: {},
      jobsitesBellIcons: {},
      weatherReport: {}
    };
  }

  /**
   * Loads all jobsites on component load.
   */
  componentDidMount = () => {
    this._isMounted = true;
    this.getJobsites();
  }

  /**
   * Refreshes the reality capture list when the active jobsite changes.
   * Gets active jobsite weather report on jobsite selection.
   * Scrolls to view full selected jobsite's card when Leaflet is not in edit
   *    mode.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.sortOption !== prevProps.sortOption){
      this.getJobsites();
    } else if (this.state.id !== prevState.id && this.state.id !== '') {
      this.getWeatherReport();
    } else if (this.props.selectedJobsite !== prevProps.selectedJobsite &&
      this.props.selectedJobsite !== '') {
        this.setState({
          id: this.props.selectedJobsite
        })
    }
    if (!this.props.editMode) {
      this.scrollToSelectedJobsite(this.state.id);
    }
  }

  /**
   * Unsets the '_isMounted' variable on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  /**
   * Toggles the jobsite deletion warning.
   */
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  /**
   * Scrolls to the active jobsite's card.
   *
   * @param {string} id - active jobsite id
   */
  scrollToSelectedJobsite = (id) => {
    const target = document.getElementById(id);
    target && target.scrollIntoView({ behavior: "smooth" });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    if(this.state.data.length === 0){
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid className='item' item xs={12}
              style={{paddingTop: '50px'}}>
                {this.renderAjout()}
            </Grid>
            <Grid className='item' item xs={12}>
              <h6 id='selectjs' className='co'>No model available</h6>
            </Grid>
          </Grid>
        </Box>
      )
    }
    else{
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid className='item' item xs={12}
                style={{paddingTop: '50px'}}>
                  {this.renderAjout()}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {this.listJobsites()}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <DeleteJobsite isOpen={this.state.modal}
            jobsiteWasDeletedCallback={this.getJobsites}/>
        </>
      )
    }
  }

  /**
   * Renders the 'add jobsite' modal.
   */
  renderAjout = () => {
    if(this.props.permission === true) {
      return  (
        <>
          <AddJobsite jobsiteWasCreatedCallback={this.getJobsites}/>
        </>
      )
    } else {
      return <h6></h6>
    }
  }

  /**
   * Renders the trash icon next to each jobsite name.
   *
   * @param {string} id - jobsite id
   * @param {string} msg - ???
   */
  loadIcon = (id, msg) => {
    if(this.props.permission === true){
      return (
        <DeleteForeverIcon className='trash'
          onClick={() => {
            this.toggle();
            this.setState(id);
            this.setState(msg);
            this.setJobsiteId(id.id);
          }}
        />
      )
    }
  }

  /**
   * Renders the jobsite list.
   */
  listJobsites = () => {
    const data = this.state.data;
    const items =[];
    for (const [value] of data.entries()) {
      if (data[value].id.id === this.state.id) {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={data[value].id.id}
              onClick={ () => { this.setState({id: data[value].id.id}); }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3 className='name'>
                      {this.renderBellIconButtonForCards(
                        this.state.jobsitesBellIcons[data[value].id.id],
                        this.state.devicesBellIcons[data[value].id.id],
                        this.state.rcBellIcons[data[value].id.id],
                        data[value].name)}
                      {' '}
                      {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                    </h3>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className='name'>Weather</h3>
                    {this.renderWeatherReport()}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h3 className='name'>Actions</h3>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className='buttonContainer'>
                            <Link component={RouterLink} to='/dashboard'
                              underline='none' className='whiteLink'>
                                <div className='center'>
                                  <Button  variant='secondary' className='button' onClick={() => {
                                    this.setJobsiteId(data[value].id.id);
                                    this.setJobsiteName(data[value].name);
                                  }}>
                                    {this.renderBellIconButton(
                                      this.state.rcBellIcons[data[value].id.id],
                                      'reality captures')}
                                  </Button>
                                </div>
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className='buttonContainer'>
                            <Link component={RouterLink} to='/devices'
                              underline='none' className='whiteLink'>
                                <div className='center'>
                                  <Button  variant='secondary' onClick={() => {
                                    this.setJobsiteId(data[value].id.id);
                                    this.setJobsiteName(data[value].name);
                                  }}>
                                    {this.renderBellIconButton(
                                      this.state.devicesBellIcons[data[value].id.id],
                                      'devices')}
                                  </Button>
                                </div>
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{
                    minHeight: {xs: '50px'},
                    maxHeight: '250px'
                  }}>
                    <div style={{
                      overflowY: 'auto',
                      height: '100%'
                    }}>
                      <h3 className='name'>Notifications</h3>
                      <ListAlarms entityId={data[value].id.id}
                        entityType='ASSET'
                        buttonWasClickedCallback={this.getJobsites}/>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        );
      } else {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={data[value].id.id}
              onClick={ () => {
                this.setState({id: data[value].id.id});
              }}>
                <h3 className='name'>
                  {this.renderBellIconButtonForCards(
                    this.state.jobsitesBellIcons[data[value].id.id],
                    this.state.devicesBellIcons[data[value].id.id],
                    this.state.rcBellIcons[data[value].id.id],
                    data[value].name)}
                  {' '}
                  {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                </h3>
            </Item>
          </Grid>
        );
      }
    }
    return items;
  }

  // WEATHER REPORT RENDERING & SERVICE
  /**
   * Renders the active jobsite's weather report.
   */
  renderWeatherReport = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <WeatherWidget style={{
            position: 'relative'
          }}>
            {this.renderCurrentWeatherReport()}
          </WeatherWidget>
        </Grid>
        <Grid item xs={12} md={6}>
          {this.renderDailyWeatherReport()}
        </Grid>
      </Grid>
    )
  }

  /**
   * Renders weather prediction min and max temperatures.
   */
  generateMinMaxTempWithColors = (min, max) => {
    return (
      <>
        <span style={{color: 'blue'}}>{min}</span>
        {', '}
        <span style={{color: 'red'}}>{max}</span>
      </>
    )
  }

  /**
   * Renders daily weather prediction report for active jobsite.
   */
  renderDailyWeatherReport = () => {
    if (this.state.weatherReport.daily) {
      const rows = [
        this.createWeatherTableData('icons', '',
          <Tooltip title={`${this.state.weatherReport.daily[0].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[0].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[1].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[1].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[2].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[2].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[3].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[3].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[4].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[4].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[5].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[5].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>,
          <Tooltip title={`${this.state.weatherReport.daily[6].weather[0].description}`}>
          <img src={`https://openweathermap.org/img/wn/` +
            `${this.state.weatherReport.daily[6].weather[0].icon}@2x.png`}
            style={{height: '50px'}}>
          </img>
          </Tooltip>),
        this.createWeatherTableData('temperature',
          <ThermostatIcon/>,
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[0].temp.min).toFixed(1),
            (this.state.weatherReport.daily[0].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[1].temp.min).toFixed(1),
            (this.state.weatherReport.daily[1].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[2].temp.min).toFixed(1),
            (this.state.weatherReport.daily[2].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[3].temp.min).toFixed(1),
            (this.state.weatherReport.daily[3].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[4].temp.min).toFixed(1),
            (this.state.weatherReport.daily[4].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[5].temp.min).toFixed(1),
            (this.state.weatherReport.daily[5].temp.max).toFixed(1)
          ),
          this.generateMinMaxTempWithColors(
            (this.state.weatherReport.daily[6].temp.min).toFixed(1),
            (this.state.weatherReport.daily[6].temp.max).toFixed(1)
          )),
        this.createWeatherTableData('windSpeed',
          <AirIcon/>,
          (this.state.weatherReport.daily[0].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[1].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[2].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[3].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[5].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[4].wind_speed/3.6).toFixed(1),
          (this.state.weatherReport.daily[6].wind_speed/3.6).toFixed(1),),
        this.createWeatherTableData('precipitation',
          <UmbrellaIcon className='rotate'/>,
          this.state.weatherReport.daily[0].rain ?
            (this.state.weatherReport.daily[0].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[1].rain ?
            (this.state.weatherReport.daily[1].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[2].rain ?
            (this.state.weatherReport.daily[2].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[3].rain ?
            (this.state.weatherReport.daily[3].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[4].rain ?
            (this.state.weatherReport.daily[4].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[5].rain ?
            (this.state.weatherReport.daily[5].rain).toFixed(0) : 0,
          this.state.weatherReport.daily[6].rain ?
            (this.state.weatherReport.daily[6].rain).toFixed(0) : 0,),
      ];
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[0].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[1].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[2].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[3].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[4].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[5].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
                <TableCell>
                  {moment(this.state.weatherReport.daily[6].dt * 1000)
                    .format('DD/MM')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {row.icon}
                  </TableCell>
                  <TableCell>
                    {row.day1Value}
                  </TableCell>
                  <TableCell>
                    {row.day2Value}
                  </TableCell>
                  <TableCell>
                    {row.day3Value}
                  </TableCell>
                  <TableCell>
                    {row.day4Value}
                  </TableCell>
                  <TableCell>
                    {row.day5Value}
                  </TableCell>
                  <TableCell>
                    {row.day6Value}
                  </TableCell>
                  <TableCell>
                    {row.day7Value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <WeatherWidget style={{position: 'relative'}}>
          <div style={{
            color: 'white',
            fontWeight: 'bold',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            No daily weather data available
          </div>
        </WeatherWidget>
      );
    }
  }

  /**
   * Renders current weather report for active jobsite.
   */
  renderCurrentWeatherReport = () => {
    if (this.state.weatherReport.current) {
      const url = `https://openweathermap.org/img/wn/` +
        `${this.state.weatherReport.current.weather[0].icon}@2x.png`;
      return (
        <Box sx={{ flexGrow: 1 }} style={{
          color: 'black',
          fontWeight: 'bold',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)'
        }} className='center'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {moment(this.state.weatherReport.current.dt * 1000)
                .format('MMMM Do YYYY, h:mm:ss a')}
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                  <ThermostatIcon/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'left'}}>
                  {this.state.weatherReport.current.temp.toFixed(1)}°C
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                  <AirIcon/>
                </Grid>
                <Grid item xs={6} style={{
                  textAlign: 'left'
                }}>
                  {(this.state.weatherReport.current.wind_speed/3.6).toFixed(1)} km/h
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                  <UmbrellaIcon className='rotate'/>
                </Grid>
                <Grid item xs={6} style={{
                  textAlign: 'left'
                }}>
                  {this.state.weatherReport.current.rain ?
                    this.state.weatherReport.current.rain['1h'] : 0} mm
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
                <img  src={url} style={{height: '150px'}}></img>
                <div>
                  {this.state.weatherReport.current.weather[0].description}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <div style={{
          color: 'white',
          fontWeight: 'bold',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          No current weather data available
        </div>
      );
    }
  }

  /**
   * Generates weather prediction table row.
   *
   * @returns {object} - weather prediction table row
   */
  createWeatherTableData = (
    key,
    icon,
    day1Value,
    day2Value,
    day3Value,
    day4Value,
    day5Value,
    day6Value,
    day7Value
  ) => {
    return { key, icon, day1Value, day2Value, day3Value, day4Value, day5Value,
      day6Value, day7Value };
  }

  /**
   * Gets active jobsite's coordinates and uses them to get it's weather report.
   */
  getWeatherReport = async () => {
    const attributes = await getAttributes(this.props.token, this.state.id,
      'ASSET');
    let lat = '';
    let long = '';
    for (let item of attributes) {
      if (item.key === 'latitude') {
        lat = item.value;
      } else if (item.key === 'longitude') {
        long = item.value;
      }
    }
    const response = await getWeatherReport(lat, long);
    this.setState({
      weatherReport: response
    });
  }

  // NOTIFICATION RENDERING & SERVICE
  /**
   * Renders bell icons next to jobsite names with active alarms.
   *
   * @param {boolean} jobsiteBellIcon - jobsite has active alarms
   * @param {boolean} devicesBellIcons - contained devices have active alarms
   * @param {boolean} rcBellIcons - contained reality captures
   *    have active alarms
   * @param {string} text - jobsite name
   * @returns {string} text - card title
   */
  renderBellIconButtonForCards = (jobsiteBellIcon, devicesBellIcons, rcBellIcons,
    text) => {
      if (jobsiteBellIcon || devicesBellIcons || rcBellIcons) {
        return (
          <>
            {text}
            {' '}
            <NotificationsActiveIcon/>
          </>
        );
      }
      return text;
  }

  /**
   * Renders bell icons next to elements with active alarms.
   *
   * @param {boolean} bellIcon - element has active alarms
   * @param {string} text - element name (as in devices or reality captures)
   * @returns {string} text - button title
   */
  renderBellIconButton = (bellIcon, text) => {
    if (bellIcon) {
      return (
        <div>
          {text}
          {' '}
          <NotificationsActiveIcon/>
        </div>
      );
    }
    return text;
  }

  /**
   * Finds out if a specific entity has active alarms.
   *
   * @param {string} entityType - entity type
   * @returns {boolean} bellIcon - bell icon status
   */
  getBellIconStatus = async (entityType) => {
    let bellIcon = false;
    for (let relation of this.state.relations) {
      if (relation.to.entityType === entityType) {
        bellIcon = await this.getNotifications(entityType, relation.to.id);
        if (bellIcon) {
          return bellIcon;
        }
      }
    }
    return bellIcon;
  }

  /**
   * Finds out if any jobsite has active alarms.
   */
  getJobsitesBellIcons = async () => {
    let jobsitesBellIcons = {};
    for (let jobsite of this.state.data) {
      const bellIcon = await this.getNotifications('ASSET', jobsite.id.id);
      jobsitesBellIcons[jobsite.id.id] = bellIcon;
    }
    this.setState({jobsitesBellIcons: jobsitesBellIcons})
  }

  /**
   * Finds out if specified entity has active alarms.
   *
   * @returns {boolean} - entity has active alarms
   */
  getNotifications = async (entityType, entityId) => {
    const response = await getAllAlarms(this.props.token, entityType, entityId);
    for (let alarm of response) {
      if (alarm.status === 'ACTIVE_UNACK') {
        return true;
      }
    }
    return false;
  }

  // JOBSITES SERVICE
  /**
   * Gets jobsites relations.
   */
  getRelations = async () => {
    for (let jobsite of this.state.data) {
      const response = await findRelationByFrom(this.props.token, jobsite.id.id,
        'ASSET');
      await this.setState({relations: response});
      let devicesBellIcons = this.state.devicesBellIcons;
      let rcBellIcons = this.state.rcBellIcons;
      devicesBellIcons[jobsite.id.id] = await this.getBellIconStatus('DEVICE');
      rcBellIcons[jobsite.id.id] = await this.getBellIconStatus('ASSET');
      this.setState({
        devicesBellIcons: devicesBellIcons,
        rcBellIcons: rcBellIcons
      });
    }
  }

  // SORTING SERVICE
  /**
   * Selects the appropriate sorting function.
   *
   * @param {[object]} jobsites - the list of jobsites
   */
  sortJobsites = (jobsites) => {
    switch (this.props.sortOption) {
      case 'date':
        this.sortOnDate(jobsites);
        break;
      case 'name':
        this.sortOnName(jobsites);
        break;
    }
  }

  /**
   * Sorts jobsites on their date.
   *
   * @param {[object]} jobsites - the list of jobsites
   */
  sortOnDate = async (jobsites) => {
    jobsites.sort(function(a, b){return a.createdTime - b.createdTime});
    await this.setState({
      data: jobsites,
      loading: false
    });
    this.getJobsitesBellIcons();
    this.getRelations();
  }

  /**
   * Sorts jobsites on their name.
   *
   * @param {[object]} jobsites - the list of jobsites
   */
  sortOnName = async (jobsites) => {
    jobsites.sort(function(a, b){
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    await this.setState({
      data: jobsites,
      loading: false
    });
    this.getJobsitesBellIcons();
    this.getRelations();
  }

  // ## JOBSITES SERVICE
  /**
   * Gets user jobsites.
   */
  getJobsites = async () => {
    this.setState({
      loading: true,
      modal: false
    });
    const jobsites = await getJobsites(this.props.token);
    this.sortJobsites(jobsites);
    this.props.jobsiteListCallback(jobsites);
  };

  // ## REDUX
   /**
   * Saves jobsite id.
   *
   * @param {string} id - reality capture id
   */
  setJobsiteId = (id) => {
    const action = { type: 'SET_JOBSITE_ID', value: id }
    this.props.dispatch(action);
  }

  /**
   * Saves jobsite name.
   *
   * @param {string} name - reality capture name
   */
  setJobsiteName = (name) => {
    const action = { type: 'SET_JOBSITE_NAME', value: name }
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    coordinates, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    coordinates: state.storeCoordinates.coordinates,
    permission: state.storePermission.allow
  }
}

export default connect(mapStateToProps)(ListJobsites);
