/**
 * Calls API to delete point of interests.
 * DELETE /point-of-interests/:pointOfInterestId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 */
export async function deletePointOfInterest (token, id) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete the point of interest',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

/**
 * Calls API to delete a jobsite / point of interest relation.
 * DELETE /point-of-interests/:pointOfInterestId/jobsite/:jobsiteId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 * @param {string} jobsiteId - jobsite id
 */
export async function deletePointOfInterestJobsiteRelation (token, id, jobsiteId) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}` +
    `/jobsite/${jobsiteId}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete the relation',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

/**
 * Calls API to delete a reality capture / point of interest relation.
 * DELETE
 *    /point-of-interests/:pointOfInterestId/reality-capture/:realityCaptureId/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 * @param {string} rcId - reality capture id
 */
export async function deletePointOfInterestRelation (token, id, rcId) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}` +
    `/reality-capture/${rcId}`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    }
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete the relation',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};

/**
 * Calls API to delete a reality capture / point of interest relation.
 * DELETE /point-of-interests/:pointOfInterestId/pictureUrl/
 *
 * @param {string} token - user JWT token
 * @param {string} id - point of interest id
 * @param {string} rcId - reality capture id
 */
export async function deletePointOfInterestPictureUrlAttribute (token, id,
    pictureUrl){
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/${id}/` +
    `pictureUrl`;
  const response = await fetch(url,{
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    body: JSON.stringify({
      pictureUrl: pictureUrl
    }),
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to delete the url',
      detail: err
    };
    console.log(errormsg);
    return errormsg;
  }
};
