// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WarningIcon from '@mui/icons-material/Warning';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import { deletePointOfInterest, deletePointOfInterestJobsiteRelation }
  from '../../../services/api/pointsOfInterest/deletePointOfInterest';
import getComments
  from '../../../services/api/comments/getComments';
import deleteComment
  from '../../../services/api/comments/deleteComment';
import store from '../../../redux/store';
import './DeleteDevice.css';

// # MAIN
class DeleteDevice extends React.Component {
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: [],
      modal: false,
      modal2: false,
      id: '',
      delmsg:'',
    };
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <>
        <Dialog open={this.props.isOpen}>
          <DialogContent>
            <p className='warningContainer'>
              <WarningIcon className='warning'/>
            </p>
            <span className='warningContainer'>
              Are you sure you want to delete this device ?
            </span>
            {loadingRender(this.state.loading, this.state.delmsg)}
          </DialogContent>
          <DialogActions>
            <Button variant='secondary'
              onClick={() => {
                this.setState({delmsg: ''});
                this.props.poiWasDeletedCallback();
              }}>
                NO
            </Button>
            <Button variant='primary'
              onClick={() => {
                this.deleteDeviceRelation(this.state.id);
                this.setState({delmsg: ''});
              }}>
                ONLY DELETE RELATION
            </Button>
            <Button variant='secondary'
              onClick={() => {
                this.deleteDevice(this.state.id);
                this.setState({delmsg: ''});
              }}>
                YES
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  /**
   * Deletes a device relation to a jobsite.
   */
  deleteDeviceRelation = async () => {
    this.setState({ loading: true });
    const response = await deletePointOfInterestJobsiteRelation(
      this.props.token, this.props.poiId, this.props.jobsiteId);
    this.setState({
      delmsg: response.message,
      loading: false,
    });
    this.props.poiWasDeletedCallback();
  };

  /**
   * Deletes a reality capture.
   */
  deleteDevice = async () => {
    this.setState({ loading: true });
    const comments = await getComments(this.props.token, this.props.poiId);
    for (let comment of comments) {
      await deleteComment(this.props.token, comment.id);
    }
    const response = await deletePointOfInterest(this.props.token,
      this.props.poiId);
    this.setState({
      delmsg: response.message,
      loading: false,
    });
    this.props.poiWasDeletedCallback();
  };
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId, permission -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    permission: state.storePermission.allow,
    commentId: state.storeCommentId.commentId
  }
}

export default connect(mapStateToProps)(DeleteDevice);
