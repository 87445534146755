// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Paper from '@mui/material/Paper';

// ## LOCAL IMPORTS
import AddDevice from '../add/AddDevice';
import AddPicture from '../add/AddPicture';
import DeleteDevice from '../delete/DeleteDevice';
import ListAlarms from '../../alarms/ListAlarms';
import { getPointsOfInterestFromJobsiteId }
  from '../../../services/api/pointsOfInterest/getPointsOfInterest';
import { deletePointOfInterest }
  from '../../../services/api/pointsOfInterest/deletePointOfInterest';
import { getAllAlarms } from '../../../services/thingsboard/alarms';
import store from '../../../redux/store';
import './ListDevices.css';

// # THEME
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'white',
  border: '2px solid rgba(0, 135, 183, 1)',
  width: '80%'
}));

// # MAIN
/**
 * This class allows the rendering of a list for all active jobsite's devices.
 *
 * @param {string} sortOption - the option the devices should be sorted on
 * @param {function} setSidePanelCallback - callback for when the comments side
 *    panel is opened
 */
class ListDevices extends React.Component{
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      coordinates: [],
      modal: false,
      id: '',
      delmsg:'',
      addPictureModalIsOpen: false,
      devicesBellIcons: {}
    };
  }

  /**
   * Loads the reality captures on component mount.
   */
  componentDidMount = () => {
    this._isMounted = true;
    this.getDevices();
  }

  /**
   * Refreshes the reality capture list when the active jobsite changes.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.sortOption !== prevProps.sortOption){
      this.getDevices();
    }
  }

  /**
   * Unsets the '_isMounted' variable on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  /**
   * Toggles the reality capture deletion warning.
   */
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    if(this.state.data.length === 0){
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid className='item' item xs={12}
              style={{paddingTop: '50px'}}>
                {this.renderAjout()}
            </Grid>
            <Grid className='item' item xs={12}>
              <h6 id='selectjs' className='co'>No device available</h6>
            </Grid>
          </Grid>
        </Box>
      )
    }
    else{
      return (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid className='item' item xs={12}
                style={{paddingTop: '50px'}}>
                  {this.renderAjout()}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {this.listDevices()}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <DeleteDevice isOpen={this.state.modal}
            poiWasDeletedCallback={this.getDevices}/>
          <AddPicture isOpen={this.state.addPictureModalIsOpen}
            poiId={this.state.id}
            setIsOpenCallback={(status) => {
              this.setState({addPictureModalIsOpen: status})
            }}/>
        </>
      )
    }
  }

  /**
   * Renders the 'add reality capture' modal.
   */
   renderAjout = () => {
     if(this.props.permission === true) {
       return  (
         <>
           <AddDevice deviceWasCreatedCallback={this.getDevices}
            jobsiteDevices={this.state.data}/>
         </>
       )
     } else {
       return <h6></h6>
     }
   }

  /**
   * Renders the trash icon next to each reality capture name.
   *
   * @param {string} id - reality capture id
   * @param {string} msg - ???
   */
  loadIcon = (id, msg) => {
    if(this.props.permission === true){
      return (
        <DeleteForeverIcon className='trash'
          onClick={() => {
            this.toggle();
            this.setState(id);
            this.setState(msg);
            this.setDeviceId(id.id);
          }}
        />
      )
    }
  }

  /**
   * Creates a list of every user's reality capture associated with
   *    selected jobsite.
   *
   * @returns {[JSX]} items - list of reality captures
   */
  listDevices = () => {
    const data = this.state.data;
    const items = [];
    for (const [value] of data.entries()) {
      if (data[value].id.id === this.state.id) {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={`device${value}`}
              onClick={ () => { this.setState({id: data[value].id.id}); }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{color: 'white'}}>
                    <h3 className='name'>
                      {this.renderBellIconButton(
                        this.state.devicesBellIcons[data[value].id.id],
                        data[value].name)}
                      {' '}
                      {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                    </h3>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 className='name'>Actions</h3>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div style={{
                            margin: 'auto',
                            width: '80%',
                            padding: '10px'
                          }}>
                            <Link component={RouterLink} to='/telemetry'
                              underline='none' className='whiteLink'>
                                <div className='center'>
                                  <Button  variant='secondary'
                                    onClick={() => {
                                      this.setDeviceId(data[value].id.id);
                                      this.setDeviceName(data[value].name);
                                      this.setDeviceType(data[value].type);
                                    }}>
                                    telemetry
                                  </Button>
                                </div>
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} className='center'>
                          <div style={{
                            margin: 'auto',
                            width: '80%',
                            padding: '10px'
                          }}>
                            <Button  variant='secondary'
                              onClick={() => {
                                this.setDeviceId(data[value].id.id);
                                this.setDeviceName(data[value].name);
                                this.props.setSidePanelCallback(true);
                              }}>
                                comments
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} className='center'>
                          <div style={{
                            margin: 'auto',
                            width: '80%',
                            padding: '10px'
                          }}>
                            <Button  variant='secondary'
                              onClick={() => {
                                this.setState({
                                  addPictureModalIsOpen: true
                                });
                              }}>
                                pictures
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{
                    minHeight: {xs: '50px'},
                    maxHeight: '350px'
                  }}>
                    <div style={{
                      overflowY: 'auto',
                      height: '100%'
                    }}>
                      <h3 className='name'>Notifications</h3>
                      <ListAlarms entityId={data[value].id.id}
                        entityType='DEVICE'
                        buttonWasClickedCallback={this.getDevices}/>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        );
      } else {
        items.push(
          <Grid item xs={12} key={value}>
            <Item className='center' id={`rc${value}`}
              onClick={ () => { this.setState({id: data[value].id.id}); }}>
                <h3 className='name'>
                  {this.renderBellIconButton(
                    this.state.devicesBellIcons[data[value].id.id],
                    data[value].name)}
                  {' '}
                  {this.loadIcon({id: data[value].id.id},{delmsg: ''})}
                </h3>
            </Item>
          </Grid>
        );
      }
    }
    return items;
  }

  /**
   * Renders the bell icon next to devices name with active alarms.
   *
   * @param {boolean} bellIcon - the device has active alarms
   * @param {string} text - the device name
   */
  renderBellIconButton = (bellIcon, text) => {
    if (bellIcon) {
      return (
        <>
          {text}
          {' '}
          <NotificationsActiveIcon/>
        </>
      );
    }
    return text;
  }

  // ## ALARMS SERVICE
  /**
   * Specifies which device has active alarms.
   */
  getDevicesBellIcons = async () => {
    let devicesBellIcons = {};
    for (let device of this.state.data) {
      const bellIcon = await this.getNotifications('DEVICE', device.id.id);
      devicesBellIcons[device.id.id] = bellIcon;
    }
    this.setState({devicesBellIcons: devicesBellIcons})
  }

  /**
   * Gets specified entity's alarms from ThingsBoard API.
   *
   * @param {string} entityType - entity type
   * @param {string} entityId - entity id
   */
  getNotifications = async (entityType, entityId) => {
    const response = await getAllAlarms(this.props.token, entityType, entityId);
    for (let alarm of response) {
      if (alarm.status === 'ACTIVE_UNACK') {
        return true;
      }
    }
    return false;
  }

  // ## DEVICES SERVICE
  /**
   * Gets user devices from selected jobsite.
   */
  getDevices = async () => {
    this.setState({ loading: true, modal: false });
    const devices = await
      getPointsOfInterestFromJobsiteId(this.props.token, this.props.jobsiteId);
    this.sortDevices(devices);
  };

  // ## SORTING SERVICE
  /**
   * Selects the appropriate sorting function.
   *
   * @param {[object]} devices - the list of devices
   */
  sortDevices = (devices) => {
    switch (this.props.sortOption) {
      case 'date':
        this.sortOnDate(devices);
        break;
      case 'name':
        this.sortOnName(devices);
        break;
    }
  }

  /**
   * Sorts devices on their date.
   *
   * @param {[object]} devices - the list of devices
   */
  sortOnDate = async (devices) => {
    devices.sort(function(a, b){
      return new Date(a.attributes.date) - new Date(b.attributes.date)
    });
    await this.setState({
      data: devices,
      loading: false
    });
    this.getDevicesBellIcons();
  }

  /**
   * Sorts devices on their name.
   *
   * @param {[object]} devices - the list of devices
   */
  sortOnName = async (devices) => {
    devices.sort(function(a, b){
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    this.setState({
      data: devices,
      loading: false
    });
    this.getDevicesBellIcons();
  }

  // ## REDUX
  /**
   * Saves rdevice id.
   *
   * @param {string} id - device id
   */
  setDeviceId = (id) => {
    const action = { type: 'SET_POI_ID', value: id }
    this.props.dispatch(action);
  }

  /**
   * Saves device name.
   *
   * @param {string} name - device name
   */
  setDeviceName = (name) => {
    const action = { type: 'SET_POI_NAME', value: name }
    this.props.dispatch(action);
  }
  /**
   * Saves device type.
   *
   * @param {string} type - device type
   */
  setDeviceType = (type) => {
    const action = { type: 'SET_POI_TYPE', value: type }
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId, rcName, poiId,
 *    coordinates, permission - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    coordinates: state.storeCoordinates.coordinates,
    permission: state.storePermission.allow
  }
}

export default connect(mapStateToProps)(ListDevices);
