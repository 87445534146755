// # IMPORTS'
import React from 'react';
import { connect } from 'react-redux';
import jwt from 'jwt-decode';
import { Button } from '@bbri/ui';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// ## LOCAL IMPORTS
import login from '../../services/thingsboard/login';
import { getPermissionsPE, getPermissionsCE }
  from '../../services/thingsboard/checkPermissions';
import store from '../../redux/store';
import './Login.css';

// ## VARIABLES
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// # MAIN
/**
 * This class allows the rendering of the login form.
 */
class Login extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
      email: '',
      authenticationFailed: false
    }
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid container direction='column' style={{marginTop: '100px'}}>
          <Grid item xs={12} className='loginContainer'
            style={{
              padding: '0',
              margin: 'auto',
              width: '80%',
              maxWidth: '500px'
            }}>
              <Item style={{
                paddingBottom: '100px'
              }}>
                <p id='titre' className='h4 text-center py-4'>Sign In</p>
                  <form onSubmit={this.submitHandler}>
                    <div id='textt'>
                      <Grid container>
                          <Grid item xs={12} className='inputContainer'>
                            <FormControl style = {{
                              width: '80%',
                              paddingBottom: '15px'
                            }}>
                              <InputLabel htmlFor='outlined-adornment-password'>
                                email
                              </InputLabel>
                              <Input
                                required
                                id='standard-adornment-weight'
                                value={this.state.email}
                                onChange={this.handleEmail}
                                aria-describedby='standard-weight-helper-text'/>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className='inputContainer'>
                            <FormControl style = {{
                              width: '80%',
                              paddingBottom: '15px'
                            }}>
                                <InputLabel htmlFor='outlined-adornment-password'>
                                  password
                                </InputLabel>
                                <Input
                                  required
                                  id='outlined-adornment-password'
                                  type={this.state.showPassword ? 'text' : 'password'}
                                  value={this.state.password}
                                  onChange={this.handlePassword}
                                  endAdornment={
                                    <InputAdornment position='end'>
                                      <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={this.handleShowPassword}
                                        edge='end'
                                      >
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label='Password'
                                />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} style={{color: 'red'}}>
                            <div hidden={!this.state.authenticationFailed}>
                              Invalid username or password
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className='h4 text-center py-4 center'
                              style={{width: '80%'}}>
                                <Button
                                  variant='secondary'
                                  className='rounded-pill orange-text'>
                                    Sign In
                                </Button>
                            </div>
                          </Grid>
                      </Grid>
                    </div>
                  </form>
              </Item>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // ## FORM SERVICE
  /**
   * Calls the login function.
   *
   * @param event - form submission event
   */
  submitHandler = async (event) => {
    event.preventDefault();
    const response = await login(this.state.email, this.state.password);
    if (response.token) {
      this.storeTokendata(response.token);
      this.getUserInfo(response.token);
      this.setLoggedIn();
    } else {
      this.setState({
        authenticationFailed: true
      })
    }
  }

  /**
   * Saves user email.
   *
   * @param event - change of email field value
   */
  handleEmail = (event) => {
    this.setState({email: event.target.value});
  }

  /**
   * Saves user password.
   *
   * @param event - change of password field value
   */
  handlePassword = (event) => {
    this.setState({password: event.target.value});
  }

  /**
   * Saves show/hide state for password.
   *
   * @param event - user clicked on the show/hide password button
   */
  handleShowPassword = (event) => {
    this.setState({showPassword: !this.state.showPassword});
  }

  // ## USER INFORMATIONS
  /**
   * Calls Thingsboard's API to get information about the connected user and
   *    and stores them.
   * Depending on Thingsboard edition (Community Edition or
   *    Proffessional Edition), gets the connected user's permissions.
   *
   * @param {string} token - connected user's JWT token
   */
  getUserInfo = (token) => {
    try {
      const userInformations = jwt(token);
      this.storeUserId(userInformations);
      if (process.env.REACT_APP_TB_EDITION === 'PE') {
        getPermissionsPE();
      } else if (process.env.REACT_APP_TB_EDITION === 'CE') {
        getPermissionsCE(userInformations);
      }
    } catch (err) {
      const errormsg = {
        message: 'Error while trying to get user informations',
        detail: err
      };
      console.log(errormsg);
    }
  };

  // ## REDUX
  /**
   * Stores connected user's customer id.
   *
   * @param {object} data - connected user's informations
   */
  storeUserId = (data) => {
    const action = { type: 'SET_ID', value: data.userId }
    this.props.dispatch(action);
  }

  /**
   * Stores connected user's JWT token.
   *
   * @param {string} token - connected user's JWT token
   */
  storeTokendata = (token) => {
    const action = { type: 'SET_TOKEN', value: token }
    this.props.dispatch(action);
  }

  /**
   * Stores user as logged in.
   */
  setLoggedIn = () => {
    const action = { type: 'LOGIN', value: true };
    this.props.dispatch(action);
  }
};

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token
  }
}

export default connect(mapStateToProps)(Login);
