// # IMPORTS
import React from 'react';
import { Button } from '@bbri/ui';
import { connect } from 'react-redux';
import { GeoapifyGeocoderAutocomplete, GeoapifyContext }
  from '@geoapify/react-geocoder-autocomplete';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';

// ## MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';

// ## LOCAL IMPORTS
import loadingRender from '../../../services/misc/loading';
import postJobsite from '../../../services/api/jobsites/postJobsite';
import store from '../../../redux/store';
import './AddJobsite.css';

// # MAIN
/**
 * This class allows the rendering of a dialog for adding jobsites.
 *
 * @param {function} jobsiteWasCreatedCallback - callback for when a jobsite
 *    is created
 */
class AddJobsite extends React.Component{
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      name : '',
      message: '',
      loading: false,
      coordinates: {}
    }
  }

  /**
   * Toggles the specified modal.
   *
   * @params {integer} nr - the modal number
   */
  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      message: ''
    });
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    const preventDefault =
      (event: React.SyntheticEvent) => event.preventDefault();
    return(
      <>
        <CachedIcon className='iSyncm'
          onClick={()=>{this.props.jobsiteWasCreatedCallback();}}/>
        <AddIcon className='iPlus'
          onClick={this.toggle(14)}/>
        <Dialog open={this.state.modal14} onClose={this.toggle(14)}
          fullWidth={true}>
            <DialogTitle>Add jobsite</DialogTitle>
            <DialogContent style={{
              height: '400px'
            }}>
              <form onSubmit={preventDefault}>
                <FormControl variant='standard' fullWidth>
                  <TextField
                    required
                    id='addJobsiteNameTextInput'
                    label='Jobsite name'
                    onChange={this.handleName}
                    variant='standard'
                  />
                </FormControl>
                <div style={{
                  marginTop: '10px'
                }}>
                  {this.renderGeoapifyAutocomplete()}
                </div>
                <div className='msg'>
                  {loadingRender(this.state.loading, this.state.message)}
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button variant='secondary' children='Close'
                onClick={this.toggle(14)}>
              </Button>
              <Button variant='primary' children='Save'
                onClick={this.submitHandler}>
              </Button>
            </DialogActions>
        </Dialog>
      </>
    )
  }

  // GEOAPIFY RENDERING & SERVICE
  /**
   * Renders the autocompletion address input.
   */
  renderGeoapifyAutocomplete = () => {
    return (
      <GeoapifyContext apiKey="687b7ce6b04f442c804c3c48eb165687">
        <GeoapifyGeocoderAutocomplete placeholder="Enter address here"
          lang='en'
          placeSelect={this.onPlaceSelect}
          />
      </GeoapifyContext>
    )
  }

  /**
   * On autocompletion suggestion selection, save the selected suggestion.
   *
   * @params {object} value - the suggestion object
   */
  onPlaceSelect = (value) => {
    if (value) {
      this.setState({
        coordinates: [
          value.properties.lat,
          value.properties.lon
        ]
      })
    }
  }

  // ## JOBSITES SERVICE
  /**
   * Creates a jobsite.
   *
   * @param {string} name - jobsite name
   * @param {[number]} coordinates - jobsite coordinates
   */
  postJobsite = async (name, coordinates) => {
    this.setState({ loading: true });
    const response = await postJobsite(this.props.token, name, coordinates);
    this.setState({
      message: response.message,
      loading: false,
    });
    if (response.detail.id) {
      this.setState({
        modal14: false
      });
      this.props.jobsiteWasCreatedCallback();
    }
  };

  // ## FORM SERVICE
  /**
   * Calls the postPlan function on form submit.
   */
  submitHandler = () => {
    this.postJobsite(this.state.name, this.state.coordinates);
  }

  /**
   * Saves reality capture name.
   *
   * @param event - change of name field value
   */
  handleName = (event) => {
    this.setState({name: event.target.value});
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId -
 *    redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
  }
}

export default connect(mapStateToProps)(AddJobsite);
