// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';

// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import ListRealityCaptures
  from '../../components/realityCaptures/list/ListRealityCaptures';
import SortRCs
  from '../../components/sort/SortRCs';
import SidePanel from '../../components/comments/sidePanel/SidePanel';
import store from '../../redux/store';
import './Dashboard.css';

// # MAIN
/**
 * This class allows the rendering of the 'Dashboard' page.
 */
class Dashboard extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      sortOption: 'date',
      sidePanelIsOpen: false
    };
  }

  /**
   * Sets the previous page name on component unmount.
   */
  componentWillUnmount = () => {
    this.setPreviousPage();
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <>
        <Box sx={{ flexGrow: 1 }} style={{overflowY: 'scroll', height: '85vh'}}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={2}>
              <Link className='back' component={RouterLink}
                to={`/${this.props.previousPage}`}
                underline='none'>
                  <ArrowBackIcon style={{fontSize: '50px'}}/>
              </Link>
            </Grid>
            <Grid item xs={10}>
              <p className='title center'>{this.renderJobsiteName()}</p>
            </Grid>
            <Grid item xs={12} className='sortContainer'>
              <div className='sortButton'>
                <SortRCs
                  sortOptionHasChangedCallback={this.sortOptionHasChanged}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              {this.renderModele()}
            </Grid>
            <Grid item xs={12} className='sortContainer'>
              <div className='sortButton deviceButton'>
                <Link className='back' component={RouterLink}
                  to='/devices'
                  underline='none'>
                    <Button  variant='secondary'>
                      {`Devices for '${this.props.name}'`}</Button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Box>
        <SidePanel isOpen={this.state.sidePanelIsOpen}
          setSidePanelCallback={this.setSidePanel}
          entityId={this.props.rcId} entityType='ASSET'
          entityName={this.props.rcName}/>
      </>
    );
  }

  /**
   * Renders the jobsite name.
   */
  renderJobsiteName = () => {
    if (this.props.name) {
      return `My Reality Captures for '${this.props.name}'`;
    } else {
      return '';
    }
  }

  /**
   * Renders the reality capture list.
   */
  renderModele = () => {
    return  (
      <>
        <div className='co'>
          <ListRealityCaptures sortOption={this.state.sortOption}
            setSidePanelCallback={this.setSidePanel}/>
        </div>
      </>
    )
  }

  // ## COMPONENT COMMUNICATION
  /**
   * Saves the selected sort option.
   *
   * @param {string} sortOption - select field value
   */
  sortOptionHasChanged = async (sortOption) => {
    this.setState({ sortOption: sortOption });
  }

  /**
   * Saves the open state of the Side Panel.
   *
   * @param {boolean} status - the state of the Side Panel
   */
  setSidePanel = (status) => {
    this.setState({ sidePanelIsOpen: status });
  }

  // ## REDUX
  /**
   * Saves tha page name as the previous page.
   */
  setPreviousPage = () => {
    const action = { type: 'SET_PREVIOUS_PAGE', value: 'dashboard' }
    this.props.dispatch(action);
  }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId, rcName, poiId,
 *    permission, previousPage - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    permission: state.storePermission.allow,
    previousPage: state.storePreviousPage.page
  }
}

export default connect(mapStateToProps)(Dashboard);
