/**
 * Get user points of interest associated to active jobsite
 * GET /point-of-interests/
 *
 * @param {string} token - user JWT token
 */
export async function getUserPointsOfInterest (token, jobsiteId) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get points of interest',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Get user points of interest associated to active jobsite
 * GET /point-of-interests/jobsite/:jobsiteId/
 *
 * @param {string} token - user JWT token
 * @param {string} jobsiteId - jobsite id
 */
export async function getPointsOfInterestFromJobsiteId (token, jobsiteId) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/jobsite/` +
    `${jobsiteId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Authorization': `bearer ${token}`,
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get points of interest',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};

/**
 * Calls API to get user points of interest associated with specified reality
 *    capture.
 * GET /point-of-interests/reality-capture/:realityCaptureId/
 *
 * @param {string} token - user JWT token
 * @param {string} rcId - reality capture id
 */
export async function getPointsOfInterestFromRCId (token, rcId) {
  const url = `${process.env.REACT_APP_HOST}/point-of-interests/` +
    `reality-capture/${rcId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to get points of interest',
      detail: err
    };
    console.log(errormsg);
    return [];
  }
};
