// # IMPORTS
import React, { useState } from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui';
import 'react-sliding-side-panel/lib/index.css';
import parse from 'html-react-parser';

// ## MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SendIcon from '@mui/icons-material/Send';

// ## LOCAL IMPORTS
import getComments from '../../../services/api/comments/getComments';
import postComment from '../../../services/api/comments/postComment';
import store from '../../../redux/store';
import './SidePanel.css';

// # THEME VARIABLES
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const ItemMe = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#000000',
}));

// # MAIN
/**
 * This class allows the rendering of a left sided side panel for entity
 *    comments.
 *
 * @param {boolean} isOpen - the side panel is open
 * @param {function} setSidePanelCallback - callback for when the side panel's
 *    state changes
 * @param {string} entityId - entity idea
 * @param {string} entityType - entity type
 * @param {string} entityName - entity name
 */
class SidePanel extends React.Component {
  _isMounted = false;

  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: [],
      content: '',
      modal: false,
      id: '',
      delmsg:'',
    };
  }

  /**
   * Sets the '_isMounted' variable to true on component mount.
   */
  componentDidMount = () => {
    this._isMounted = true;
  }

  /**
   * Gets all entity's comments on component update if the SidePanel is open
   *    and scrolls to the bottom of the SidePanel.
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.isOpen != prevProps.isOpen && this.props.isOpen === true) {
      this.getAndSaveComments();
    }
    this.scrollToBottom();
  }

  /**
   * Sets the '_isMounted' variable to false on component unmount.
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  }

  /**
   * Scrolls to the bottom of the SidePanel.
   */
  scrollToBottom = () => {
    const bottom = document.getElementById('bottom');
    bottom && bottom.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Generates a pretty date for display within each comment card.
   *
   * @param {string} createdAt - comment creation date
   * @returns {string}- pretty date
   */
  generatePrettyDate = (createdAt) => {
    const date = new Date(createdAt);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` +
      ` ${date.getHours()}:` +
      `${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <SlidingPanel
        type='left'
        isOpen={this.props.isOpen}
        size='80'
        panelClassName='sidePannelContent'
        panelContainerClassName='sidePannel'
        backdropClicked={() => this.props.setSidePanelCallback(false)}
      >
        <p className='title'>Comments for '{this.props.entityName}'</p>
        <form onSubmit={this.submitHandler}>
          <Box sx={{ flexGrow: 1, paddingBottom: '100px'}}>
            <Grid container spacing={2}>
              {this.renderComments()}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField style={{ width: '100%' }}
                    required
                    id="outlined-textarea"
                    label="Add a comment"
                    onChange={this.handleCom}
                    value={this.state.content}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} id='bottom'>
                <Button type='submit'>
                  <SendIcon/>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </SlidingPanel>
    );
  }

  /**
   * Renders the comments.
   */
  renderComments = () => {
    if(this.state.comments.length === 0){
      return (
        <Grid item xs={12} style={{textAlign: 'center'}}>
          No comment available
        </Grid>
      )
    } else{
      const comments = this.state.comments;
      const items = [];
      let date = '';
      for (const [value] of comments.entries()) {
        date = this.generatePrettyDate(comments[value].createdAt);
        if (comments[value].userId === this.props.id) {
          items.push(
            <Grid item xs={12} key={value}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}
                  direction="row"
                  justifyContent="flex-end">
                    <Grid item xs={8}>
                      <ItemMe>
                        <Box sx={{ flexGrow: 1, overflowX: 'auto' }}>
                          <Grid container spacing={2}>
                              <Grid item xs={8} style={{textAlign: 'left'}}>
                                {comments[value].userEmail}
                              </Grid>
                              <Grid item xs={4} style={{textAlign: 'right'}}>
                                {date}
                              </Grid>
                              <Grid item xs={12} style={{textAlign: 'left'}}>
                              {parse(comments[value].content)}

                              </Grid>
                          </Grid>
                        </Box>
                      </ItemMe>
                    </Grid>
                </Grid>
              </Box>
            </Grid>
          )
        } else {
          items.push(
            <Grid item xs={12} key={value}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}
                  direction="row"
                  justifyContent="flex-start">
                    <Grid item xs={8}>
                      <Item>
                        <Box sx={{ flexGrow: 1, overflowX: 'auto' }}>
                          <Grid container spacing={2}>
                              <Grid item xs={8} style={{textAlign: 'left'}}>
                                {comments[value].userEmail}
                              </Grid>
                              <Grid item xs={4} style={{textAlign: 'right'}}>
                                {date}
                              </Grid>
                              <Grid item xs={12} style={{textAlign: 'left'}}>
                              {parse(comments[value].content)}
                              </Grid>
                          </Grid>
                        </Box>
                      </Item>
                    </Grid>
                </Grid>
              </Box>
            </Grid>
          );
        }
      }
      return items;
    }
  }

  // ## FORM SERVICE
 /**
  * Calls the postComment service function and saves the response message.
  *
  * @param event - change of content field value
  */
  submitHandler = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const response = await postComment(this.props.token, this.state.content,
      this.props.entityId, this.props.entityType);
    this.setState({
      message: response.message,
      loading: false,
      content: ''
    });
    this.getAndSaveComments();
  }

  /**
   * Saves comment content.
   *
   * @param event - change of content field value
   */
  handleCom = (event) => {
    this.setState({content : event.target.value})
  }

  // ## COMMENTS SERVICE
  /**
   * Calls getComments service function and save the response.
   */
  getAndSaveComments = async () => {
    this.setState({ loading: true });
    const comments = await getComments(this.props.token, this.props.entityId);
    if (this._isMounted) {
      this.setState({
        comments: comments,
        loading: false,
      });
    }
  }
};

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, jobsiteName, jobsiteId, rcId,
 *    rcName - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    jobsiteName: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name
  }
}

export default connect(mapStateToProps)(SidePanel);
