/**
 * Get weather report for given latitude and longitude.
 *
 * @param {float} lat - latitude
 * @param {float} long - longitude
 */
export async function getWeatherReport (lat, long) {
  const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&` +
    `lon=${long}&appid=${process.env.REACT_APP_OPENWEATHERMAP_APIKEY}&` +
    `units=metric&exclude=hourly,minutely`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    },
  });
  try {
    const jsonResponse = await response.json();
    if (jsonResponse.status) { throw jsonResponse; }
    return jsonResponse;
  } catch (err) {
    const errormsg = {
      message: 'Error while trying to weather report',
      detail: err
    };
    console.log(errormsg);
    return {};
  }
};
