const initialState = { CoordAnim: []}

function storeCoordAnimation(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SET_COORDINATES_ANIMATION':
      nextState = { CoordAnim:  action.value }
      return nextState || state
    default:
        return state
  }
}

export default storeCoordAnimation;
