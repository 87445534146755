// # IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@bbri/ui'

// ## MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ## ROUTING
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, } from '@material-ui/core';

// ## LOCAL IMPORTS
import ListDevices
  from '../../components/pointsOfInterest/list/ListDevices';
import SortDevices
  from '../../components/sort/SortDevices';
import SidePanel from '../../components/comments/sidePanel/SidePanel';
import store from '../../redux/store';
import './Devices.css';

// # MAIN
/**
 * This class allows the rendering of the 'Devices' page.
 */
class Devices extends React.Component {
  /**
   * Initiates the component's state.
   */
  constructor(props) {
    super(props);
    this.state = {
      sortOption: 'date',
      sidePanelIsOpen: false
    };
  }

  /**
   * Sets the previous page name on component unmount.
   */
  componentWillUnmount = () => {
    this.setPreviousPage();
  }

  // ## COMPONENT RENDERING
  /**
   * Renders the component.
   */
  render = () => {
    return (
      <>
        <Box sx={{ flexGrow: 1 }} style={{overflowY: 'scroll', height: '85vh'}}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={2}>
              <Link className='back' component={RouterLink}
                to={`/${this.props.previousPage}`}
                underline='none'>
                  <ArrowBackIcon style={{fontSize: '50px'}}/>
              </Link>
            </Grid>
            <Grid item xs={10}>
              <p className='title center'>{this.renderDeviceName()}</p>
            </Grid>
            <Grid item xs={12} className='sortContainer'>
              <div className='sortButton'>
                <SortDevices
                  sortOptionHasChangedCallback={this.sortOptionHasChanged}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              {this.renderDevices()}
            </Grid>
          </Grid>
          <Grid item xs={12} className='sortContainer'>
            <div className='sortButton deviceButton'>
              <Link className='back' component={RouterLink}
                to='/dashboard'
                underline='none'>
                  <Button  variant='secondary'>
                    {`Reality Captures for '${this.props.name}'`}</Button>
              </Link>
            </div>
          </Grid>
        </Box>
        <SidePanel isOpen={this.state.sidePanelIsOpen}
          setSidePanelCallback={this.setSidePanel}
          entityId={this.props.poiId} entityType='DEVICE'
          entityName={this.props.poiName}/>
      </>
    );
  }

  /**
   * Renders the jobsite name.
   */
  renderDeviceName = () => {
    if (this.props.name) {
      return `My devices for '${this.props.name}'`;
    } else {
      return '';
    }
  }

  /**
   * Renders the reality capture list.
   */
  renderDevices = () => {
    return  (
      <>
        <div className='co'>
          <ListDevices sortOption={this.state.sortOption}
            setSidePanelCallback={this.setSidePanel}/>
        </div>
      </>
    )
  }

  // ## COMPONENT COMMUNICATION
  /**
   * Saves the selected sort option.
   *
   * @param {string} sortOption - select field value
   */
  sortOptionHasChanged = async (sortOption) => {
    this.setState({ sortOption: sortOption });
  }

  /**
   * Set the open state of the Side Panel.
   *
   * @param {boolean} status - the state of the Side Panel
   */
  setSidePanel = (status) => {
    this.setState({ sidePanelIsOpen: status });
  }

  // ## REDUX
  /**
   * Saves the page name as the previous page.
   */
   setPreviousPage = () => {
     const action = { type: 'SET_PREVIOUS_PAGE', value: 'devices' }
     this.props.dispatch(action);
   }
}

/**
 * Maps redux state to props
 *
 * @param state - the entire redux state
 * @returns id, isLoggedIn, token, name, jobsiteId, rcId, rcName, poiId,
 *    poiName, permission, previousPage - redux states mapped to props
 */
const mapStateToProps = (state) => {
  return {
    id: state.storeUserId.id,
    isLoggedIn: state.isLogged.isLoggedIn,
    token: state.storeToken.token,
    name: state.storeActiveJobsiteName.name,
    jobsiteId: state.storeActiveJobsiteId.jobsiteId,
    rcId: state.storeActiveRCId.rcId,
    rcName: state.storeActiveRCName.name,
    poiId: state.storeActivePoIId.poiId,
    poiName: state.storeActivePoIName.poiName,
    permission: state.storePermission.allow,
    previousPage: state.storePreviousPage.page
  }
}

export default connect(mapStateToProps)(Devices);
